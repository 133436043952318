import styled, { css } from 'styled-components';
import { Drawer as CustomDrawer } from 'antd';

type CategoryProps = { active?: boolean };

export const CategoryList = styled.div`
  display: flex;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  ${({ theme }) => css`
    border-top: 0.5px solid ${theme.colors.lightGray};
    padding: ${theme.spacings.xsmall} 0;
    background-color: ${theme.colors.white};
    overflow: scroll;
    overflow-y: hidden;
    padding-right: ${theme.spacings.xsmall};
  `};
`;

export const Category = styled.small<CategoryProps>`
  ${({ theme, active }) => css`
    margin: 0 ${theme.spacings.xsmall};
    color: ${active ? theme.colors.primary : theme.colors.secondary};
    font-weight: ${theme.font.bold};
    white-space: nowrap;
    font-size: 1.1rem;

    &:hover {
      cursor: pointer;
    }
  `}
`;

export const Header = styled.header`
  height: 8vh;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  ${({ theme }) => css`
    padding: 0 ${theme.spacings.xxsmall};
  `}
`;

export const Order = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    padding: ${theme.spacings.xxsmall};
    border-radius: ${theme.border.radius.normal};
    b {
      color: ${theme.colors.white};
    }

    span {
      border-radius: 50%;
      background-color: ${theme.colors.white};
      width: 22px;
      text-align: center;
      font-weight: bold;
    }
  `}
`;

export const Fixed = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  ${({ theme }) => css`
    z-index: ${theme.layers.alwaysOnTop};
  `}
`;

export const SearchWrapper = styled.div`
  position: absolute;
  top: 55px;
  ${({ theme }) => css`
    padding: ${theme.spacings.xsmall} ${theme.spacings.xxsmall};

    background-color: ${theme.colors.white};
    z-index: ${theme.layers.alwaysOnTop};
    width: 100%;

    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  `}
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

export const SearchActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  input {
    ${({ theme }) => css`
      margin-right: ${theme.spacings.xxsmall};
    `}
  }

  button {
    ${({ theme }) => css`
      padding: 0 ${theme.spacings.xxsmall};
    `}
  }
`;

export const Unity = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  svg {
    ${({ theme }) => css`
      margin-right: ${theme.spacings.xxsmall};
    `}
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  > svg {
    ${({ theme }) => css`
      margin-right: ${theme.spacings.xxsmall};
    `}
  }

  > img {
    ${({ theme }) => css`
      margin-right: ${theme.spacings.xsmall};
      width: ${theme.spacings.xsmall};
    `}
  }

  > span {
    ${({ theme }) => css`
      margin-right: ${theme.spacings.xxsmall};
      color: ${theme.colors.white};
      border-radius: 50%;
      background-color: ${theme.colors.primary};
      width: 2rem;
      font-size: ${theme.font.sizes.xsmall};
      text-align: center;
      font-weight: bold;
    `}
  }
`;

export const DrawerActions = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
`;

export const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: column;
  }
`;

export const Drawer = styled(CustomDrawer)`
  .ant-drawer-body {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    ${({ theme }) => css`
      padding: 0 ${theme.spacings.xsmall};
      padding-top: ${theme.spacings.medium};
      padding-bottom: ${theme.spacings.xxsmall};
      z-index: calc(${theme.layers.alwaysOnTop} + 1);
    `};
  }
`;

export const Table = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  ${({ theme }) => css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.secondary};
    border-radius: ${theme.border.radius.normal};
    padding: 0 10px;

    small {
      font-size: 1rem;
    }

    h2 {
      color: ${theme.colors.white};
    }
  `}
`;

export const DrawerFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ClickableButton = styled.div`
  width: 4rem;
  height: 4rem;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0 0.3rem;
  cursor: pointer;
`;
