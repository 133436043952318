import { collection, query as Query } from '@firebase/firestore';
import { useFirestoreQuery } from '@react-query-firebase/firestore';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import { Divider, Tag } from 'antd';
import OrderEnabled from 'components/OrderEnabled';
import Product from 'components/Product';
import Quantity from 'components/Quantity';
import { where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import api from 'services/api';
import { firestore } from 'services/firebase';
import { Creators as AuthCreators } from 'store/modules/auth/actions';
import { Creators as CartCreators } from 'store/modules/cart/actions';
import { Creators as OrderCreators } from 'store/modules/order/actions';
import { RootType } from 'store/modules/rootReducer';
import Box from 'stories/general/Box';
import Button from 'stories/general/Button';
import { Small, Title } from 'stories/typography';
import Space from 'stories/utils/Space';
import { getCurrency } from 'utils/helpers';
import * as S from './styles';

const currency = {
  style: 'currency',
  currency: 'BRL',
};

const calculate = (total: number, amount: number) => total + amount;

const Cart = () => {
  const [remove, setRemove] = useState<number | string | undefined>(undefined);
  const [loadCart, setLoadCart] = useState(false);
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [visibleSolo, setVisibleSolo] = useState(false);
  const [visibleAll, setVisibleAll] = useState(false);
  const [showFinishBottom, setshowFinishBottom] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    cart: { data: cart, subtotal, orders, loadingCart },
    order: { loading, orderOwnerId },
    establishment: {
      origin,
      table,
      unity,
      order_enabled,
      hide_price_enabled: isHidingPrice,
    },
  } = useSelector((state: RootType) => state);
  const [ownerOrder, setOwnerOrder] = useState<number | null>();
  const isShowPrice = !isHidingPrice;

  const id = `${unity?.id}`;
  const tableNumber = `${table}`;

  const ref = Query(
    collection(firestore, id, tableNumber, 'users'),
    where('status', '!=', 'idle')
  );

  const refSend = Query(
    collection(firestore, id, tableNumber, 'orders_sent'),
    where('id', '==', orderOwnerId)
  );

  const OrdersSent = useFirestoreQuery(
    ['tables.orders_sent'],
    refSend,
    { subscribe: true },
    {
      cacheTime: 0,
      refetchOnMount: 'always',
      staleTime: Infinity,
      onSuccess() {
        handleSendSuccessAll();
      },
    }
  );

  const handleSendSuccessAll = () => {
    if (OrdersSent.data?.empty) {
      OrdersSent.refetch();
      return;
    }

    if (OrdersSent.data?.empty == undefined) {
      return;
    }

    dispatch(OrderCreators.clearOrderOwner());
    history.push('/success');
  };

  useFirestoreQuery(
    ['tables'],
    ref,
    { subscribe: true },
    {
      cacheTime: 0,
      onSuccess() {
        if (OrdersSent.data?.empty) {
          dispatch(CartCreators.loadCartRequest());
        }
      },
    }
  );

  const handleOnChangeQuantity = async (
    index: number,
    quantity: number,
    id: string
  ) => {
    dispatch(
      CartCreators.edit({
        id: index,
        quantity,
      })
    );

    await api.put(`menu/v2/units/${unity?.id}/orders/review/item/${id}`, {
      quantity,
    });
  };

  const handleSendOrder = () => dispatch(OrderCreators.sendOrderRequest());

  const handleCountCart = (ready: boolean) => {
    const ordersFiltered = orders.filter((data) => {
      if (data.owner === true) {
        return false;
      }

      if (ready && data.status == 'ready') {
        return true;
      }

      if (!ready && data.status != 'ready') {
        return true;
      }
    });

    return ordersFiltered.length;
  };

  handleCountCart(true);

  useEffect(() => {
    if (!loadCart) {
      setLoadCart(true);
      dispatch(CartCreators.loadCartRequest());
    }
  }, [loadCart, dispatch]);

  useEffect(() => {
    if (orders && (ownerOrder == -1 || ownerOrder == undefined)) {
      setOwnerOrder(
        orders.findIndex((item: { owner: boolean }) => item.owner == true)
      );
    }
  }, [orders, ownerOrder]);

  const handleShowModal = (solo: boolean) => {
    let ready = 0;
    let choise = 0;
    let total = 0;

    orders.map((order) => {
      total++;
      if (order.status == 'ready') {
        ready++;
        return;
      }

      choise++;
    });

    if (total == ready && !solo) {
      setVisibleConfirm(true);
    }

    if (choise > 0 && !solo) {
      setVisibleAll(true);
    }

    if (solo) {
      setVisibleSolo(true);
    }

    setshowFinishBottom(true);
  };

  const handleSendCart = (all: boolean) => {
    const ordersId: Array<string> = [];
    if (all) {
      orders.map((order) => {
        if (order.status == 'ready') {
          ordersId.push(order.id);
        }
      });
    }

    if (!all) {
      orders.map((order) => {
        if (order.owner) {
          ordersId.push(order.id);
        }
      });
    }

    if (orders.length > 0) {
      dispatch(CartCreators.sendCartRequest(ordersId));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(AuthCreators.reactivateRequest());
    };
  }, [dispatch]);

  return (
    <S.Wrapper>
      <div>
        <Title level={2}>Revise seu pedido</Title>
      </div>
      {orders &&
        orders.length > 0 &&
        ownerOrder != null &&
        ownerOrder != undefined &&
        ownerOrder != -1 && (
          <S.ProductList>
            {orders[ownerOrder].items.map((product, index) => (
              <div key={index}>
                <Product
                  title={product.title}
                  description={product.description ? product.description : ''}
                  price={product.full_price}
                  image={product.images[0]?.url || null}
                  showPrice={isShowPrice}
                />
                <Space size={1}>
                  {product.complements.length > 0 && (
                    <>
                      {product.complements?.map((complement) => (
                        <div key={complement.id}>
                          <Small>{complement.title}</Small>
                        </div>
                      ))}
                    </>
                  )}
                  {product.observation && (
                    <Small>
                      <strong>Obs: </strong>
                      {product.observation}
                    </Small>
                  )}
                </Space>
                <S.ProductFooter>
                  <div>
                    <Quantity
                      min={1}
                      value={product.quantity}
                      onChange={(value) =>
                        handleOnChangeQuantity(index, value, product.id)
                      }
                    />
                    <Title level={5}>
                      {getCurrency(
                        product.quantity * product.full_price
                      ).toLocaleString('pt-BR', currency)}
                    </Title>
                  </div>
                  {remove !== index && (
                    <S.Remove onClick={() => setRemove(index)}>
                      Remover
                    </S.Remove>
                  )}
                  {remove === index && (
                    <Button
                      size="small"
                      onClick={async () => {
                        dispatch(CartCreators.remove(index));
                        setRemove(undefined);

                        await api.delete(
                          `menu/v2/units/${unity?.id}/orders/review/item/${product.id}`
                        );
                      }}
                    >
                      Confirmar
                    </Button>
                  )}
                </S.ProductFooter>
                {index !== cart.length - 1 && <Divider />}
              </div>
            ))}
            <div>
              <Title level={6}>Subtotal</Title>
              <Title level={3}>
                {orders[ownerOrder].items.length > 0
                  ? getCurrency(
                      orders[ownerOrder].items
                        .map((item) => item.full_price * item.quantity)
                        .reduce(calculate)
                    ).toLocaleString('pt-BR', currency)
                  : getCurrency(0).toLocaleString('pt-BR', currency)}
              </Title>
            </div>
            <br />
          </S.ProductList>
        )}

      <S.AddItens>
        <Button
          type="outlineBlack"
          onClick={() => history.push(`/${origin}/${unity?.id}/${table}`)}
        >
          Adicionar mais itens
        </Button>
      </S.AddItens>
      <Divider />
      <OrderEnabled>
        <S.TableOrders>
          <Title level={2}>Pedidos da mesa</Title>
          {orders &&
            orders.map((data) => {
              if (data.owner === true) {
                return null;
              }

              return (
                <>
                  <Box key={data.id}>
                    <div>
                      <Title level={5}>
                        {data.username}{' '}
                        <Tag
                          color={data.status == 'ready' ? '#269452' : '#E9BB63'}
                        >
                          {data.status == 'ready'
                            ? 'pronto para enviar'
                            : 'escolhendo...'}
                        </Tag>
                      </Title>

                      <br />
                      {data.items.map((item) => {
                        return (
                          <Title level={6} key={item.id}>
                            {item.quantity} {item.title}
                          </Title>
                        );
                      })}
                    </div>
                  </Box>
                  <br />
                </>
              );
            })}
        </S.TableOrders>
      </OrderEnabled>

      <OrderEnabled>
        {!order_enabled && (
          <S.Finish>
            <div>
              <Title level={2}>Tudo Pronto?</Title>
              <div>
                <Title level={6}>Subtotal</Title>
                <Title level={3}>
                  {getCurrency(subtotal).toLocaleString('pt-BR', currency)}
                </Title>
              </div>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              disabled={cart.length === 0}
              loading={loading}
              onClick={handleSendOrder}
            >
              Finalizar pedido
            </Button>
          </S.Finish>
        )}

        <S.Finish hidden={showFinishBottom}>
          <div>
            <Title level={2}>Tudo Pronto?</Title>
          </div>
          <div>
            <Button
              disabled={!!cart.length || loadingCart}
              loading={loading || loadingCart}
              type="outline"
              fullWidth
              onClick={() => handleShowModal(true)}
            >
              {loadingCart ? (
                <S.LoadingWrapper>
                  <span>Enviando pedido...</span>
                </S.LoadingWrapper>
              ) : (
                'Enviar sozinho'
              )}
            </Button>
            <Button
              disabled={!!cart.length || loadingCart}
              loading={loading || loadingCart}
              fullWidth
              onClick={() => handleShowModal(false)}
            >
              {loadingCart ? (
                <S.LoadingWrapper>
                  <span>Enviando pedido...</span>
                </S.LoadingWrapper>
              ) : (
                'Enviar todos'
              )}
            </Button>
          </div>
        </S.Finish>
        <S.FooterConfirm hidden={!showFinishBottom}>
          {visibleConfirm && (
            <>
              <S.CloseBox>
                <CloseOutline
                  size={25}
                  onClick={() => {
                    setshowFinishBottom(false);
                    setVisibleConfirm(false);
                  }}
                />
              </S.CloseBox>
              <Title level={5}>
                Tem certeza que deseja enviar todos os pedidos da sua mesa ?
              </Title>
              <p>
                Além do seu pedido você enviará para a cozinha o pedido de{' '}
                <strong>
                  {handleCountCart(true)}
                  {handleCountCart(true) == 1 ? ' pessoa' : ' pessoas'}
                </strong>{' '}
                da sua mesa com o status &quot;pronto para enviar&quot;. Deseja
                continuar?
              </p>
              <S.Footer>
                <Button
                  size="large"
                  onClick={() => {
                    handleSendCart(false);
                    setVisibleConfirm(false);
                    setshowFinishBottom(false);
                  }}
                  type="outline"
                >
                  Enviar meu pedido sozinho
                </Button>
                <Button
                  size="large"
                  onClick={() => {
                    handleSendCart(true);
                    setVisibleConfirm(false);
                    setshowFinishBottom(false);
                  }}
                >
                  Enviar todos os pedidos da mesa
                </Button>
              </S.Footer>
            </>
          )}
          {visibleSolo && (
            <>
              <S.CloseBox>
                <CloseOutline
                  size={25}
                  onClick={() => {
                    setshowFinishBottom(false);
                    setVisibleSolo(false);
                  }}
                />
              </S.CloseBox>
              <Title level={5}>Tem certeza que deseja pedir sozinho?</Title>
              <p>
                Você pode aguardar todos as pessoas da mesa para enviarem os
                pedidos juntos, ou fazer apenas o seu pedido.{' '}
              </p>
              <S.Footer>
                <Button
                  size="large"
                  type="outline"
                  onClick={() => {
                    setVisibleSolo(false);
                    setshowFinishBottom(false);
                  }}
                >
                  Voltar e esperar todos da mesa
                </Button>
                <Button
                  size="large"
                  onClick={() => {
                    handleSendCart(false);
                    setVisibleSolo(false);
                    setshowFinishBottom(false);
                  }}
                >
                  Enviar meu pedido sozinho
                </Button>
              </S.Footer>
            </>
          )}
          {visibleAll && (
            <>
              <S.CloseBox>
                <CloseOutline
                  size={25}
                  onClick={() => {
                    setshowFinishBottom(false);
                    setVisibleAll(false);
                  }}
                />
              </S.CloseBox>
              <Title level={5}>
                Tem certeza que deseja enviar todos os pedidos da sua mesa ?
              </Title>
              <p>
                Hmmm... parece que <strong>{handleCountCart(false)}</strong>
                {handleCountCart(false) == 1 ? (
                  <>
                    {' '}
                    <strong>pessoa</strong> está escolhendo!
                  </>
                ) : (
                  <>
                    {' '}
                    <strong>pessoas</strong> ainda estão escolhendo!{' '}
                  </>
                )}{' '}
                Deseja mesmo enviar todos os pedidos para a cozinha?
              </p>
              <S.Footer>
                <Button
                  size="large"
                  onClick={() => {
                    setVisibleAll(false);
                    setshowFinishBottom(false);
                  }}
                >
                  Voltar e esperar todos da mesa
                </Button>
                <Button
                  size="large"
                  onClick={() => {
                    handleSendCart(true);
                    setVisibleAll(false);
                    setshowFinishBottom(false);
                  }}
                  type="outline"
                >
                  Enviar todos os pedidos da mesa
                </Button>
              </S.Footer>
            </>
          )}
        </S.FooterConfirm>
      </OrderEnabled>
    </S.Wrapper>
  );
};

export default Cart;
