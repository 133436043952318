import { CheckboxValueType } from 'antd/es/checkbox/Group';
import produce from 'immer';
import { ProductItemProps } from 'store/modules/product/reducer';
import { Types as CartTypes } from './actions';

export type ProductProps = {
  quantity: number;
  subtotal: number;
  observation?: string;
  complements: Array<{
    id: string;
    title?: string;
    type: string;
    options: Array<{
      id: string | CheckboxValueType;
      title: string;
      quantity: number | string;
      price?: number;
    }>;
  }>;
} & Omit<ProductItemProps, 'complements'>;

export type ItemProps = {
  id: string;
  title: string;
  description?: string;
  quantity: number;
  price: number;
  full_price: number;
  observation?: string;
  complements: Array<{
    id: string;
    title?: string;
    type: string;
    options: Array<{
      id: string | CheckboxValueType;
      title: string;
      quantity: number | string;
      price?: number;
    }>;
  }>;
  images: Array<{ url: string }>;
};

export type OrdersProps = {
  id: string;
  items: Array<ItemProps>;
  owner: boolean;
  status: string;
  table: string | number;
  user_identifier: string;
  username: string;
  subtotal: number;
};

export type CartProps = {
  loading: boolean;
  loadingCart: boolean;
  data: Array<ProductProps>;
  orders: Array<OrdersProps>;
  subtotal: number;
  quantity: number;
  status: string;
};

export const INITIAL_STATE: CartProps = {
  loading: false,
  loadingCart: false,
  data: [],
  orders: [],
  subtotal: 0,
  quantity: 0,
  status: '',
};

const calculate = (total: number, amount: number) => total + amount;
const quantity = (total: number, amount: number) => total + amount;

const cart = produce((draft: CartProps, action) => {
  switch (action.type) {
    case CartTypes.ADD:
      draft.data.push(action.payload);
      draft.subtotal = draft.data
        .map((product) => product.subtotal)
        .reduce(calculate);
      draft.quantity = draft.data
        .map((product) => product.quantity)
        .reduce(quantity);
      break;
    case CartTypes.EDIT:
      draft.data = draft.data.map((product, index) => {
        if (index === action.payload.id) {
          return {
            ...product,
            quantity: action.payload.quantity,
            subtotal: action.payload.quantity * product.price,
          };
        }
        return product;
      });

      draft.orders = draft.orders.map((order) => {
        if (order.owner) {
          return {
            ...order,
            items: order.items.map((item, index) => {
              if (index === action.payload.id) {
                return {
                  ...item,
                  quantity: action.payload.quantity,
                };
              }
              return item;
            }),
          };
        }

        return order;
      });

      draft.quantity =
        draft.data.length > 0
          ? draft.data.map((product) => product.quantity).reduce(quantity)
          : 0;

      draft.quantity =
        draft.data.length > 0
          ? draft.data.map((product) => product.quantity).reduce(quantity)
          : 0;
      draft.subtotal =
        draft.data.length > 0
          ? draft.data.map((product) => product.subtotal).reduce(calculate)
          : 0;
      break;

    case CartTypes.REMOVE:
      draft.data = draft.data.filter((_, index) => {
        return index !== action.payload;
      });

      draft.orders = draft.orders.map((order) => {
        if (order.owner) {
          return {
            ...order,
            items: order.items.filter((_, index) => {
              return index !== action.payload;
            }),
          };
        }

        return order;
      });

      draft.orders = draft.orders.map((order) => {
        if (order.owner) {
          return {
            ...order,
            subtotal:
              order.items.length > 0
                ? order.items
                    .map((item) => item.quantity * item.price)
                    .reduce(quantity)
                : 0,
          };
        }

        return order;
      });

      draft.quantity =
        draft.data.length > 0
          ? draft.data.map((product) => product.quantity).reduce(quantity)
          : 0;
      draft.subtotal =
        draft.data.length > 0
          ? draft.data.map((product) => product.subtotal).reduce(calculate)
          : 0;
      break;

    case CartTypes.RESET:
      draft.data = [];
      draft.quantity = 0;
      draft.subtotal = 0;
      break;

    case CartTypes.LOAD_CART_STATUS_REQUEST:
      draft.loading = true;
      break;

    case CartTypes.LOAD_CART_STATUS_SUCCESS:
      draft.loading = false;
      draft.status = action.payload;
      break;

    case CartTypes.LOAD_CART_STATUS_FAILED:
      draft.loading = false;
      draft.status = '';
      break;

    case CartTypes.LOAD_CART_REQUEST:
      draft.loading = true;
      break;

    case CartTypes.LOAD_CART_SUCCESS:
      draft.loading = false;
      draft.orders = action.payload;
      break;

    case CartTypes.LOAD_CART_FAILED:
      draft.loading = false;
      break;

    case CartTypes.SEND_CART_REQUEST:
      draft.loading = true;
      draft.loadingCart = true;
      break;

    case CartTypes.SEND_CART_SUCCESS:
      draft.loading = false;
      draft.loadingCart = false;
      break;

    case CartTypes.SEND_CART_FAILED:
      draft.loading = false;
      draft.loadingCart = false;
      break;
  }
}, INITIAL_STATE);

export default cart;
