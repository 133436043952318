import produce from 'immer';
import { Types as BillingTypes } from './actions';

export type BillingItemProps = {
  id: string;
  title: string;
  quantity: number;
  price: number;
  total: number;
};

export type BillingProps = {
  loading: boolean;
  paid: number;
  total: number;
  subtotal: number;
  discount: number;
  tip: number;
  data: Array<BillingItemProps>;
};

export const INITIAL_STATE: BillingProps = {
  loading: true,
  data: [],
  paid: 0,
  total: 0,
  subtotal: 0,
  discount: 0,
  tip: 0,
};

const billing = produce((draft: BillingProps, action) => {
  switch (action.type) {
    case BillingTypes.GET_BILLING_REQUEST:
      draft.loading = true;
      break;
    case BillingTypes.GET_BILLING_SUCCESS:
      draft.loading = false;
      draft.data = action.payload.items;
      draft.tip = action.payload.tip;
      draft.discount = action.payload.discount;
      draft.paid = action.payload.paid;
      draft.subtotal = action.payload.subtotal;
      draft.total = action.payload.total;
      break;
    case BillingTypes.GET_BILLING_FAILED:
      draft.loading = false;
      break;
  }
}, INITIAL_STATE);

export default billing;
