import { Divider as CustomDivider } from 'antd';
import Box from 'stories/general/Box';
import styled, { css } from 'styled-components';

export const ProductList = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    padding: ${theme.spacings.xxsmall} 0;
    border-radius: ${theme.border.radius.normal};
  `}
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

export const CategorySection = styled.section`
  ${({ theme }) => css`
    h2 {
      padding: 0 ${theme.spacings.xsmall};
    }
  `}
`;

export const Wrapper = styled.div`
  height: 100%;
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.large};
  `}
`;

type CoverProps = {
  image: string;
};

export const Cover = styled.div<CoverProps>`
  object-fit: cover;
  background-image: url(${({ image }) => image});
  position: relative;
  width: 100%;
  height: 25rem;
  background-repeat: no-repeat;
  z-index: 1;

  background-position: center;
`;

export const Overlay = styled.div`
  position: relative;
  width: 100%;
  height: 25rem;

  background-color: rgba(0, 0, 0, 0.4);
`;

export const ProductBox = styled.div`
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Back = styled.small`
  position: absolute;
  ${({ theme }) => css`
    color: #676666;
    font-weight: ${theme.font.semiBold};
    font-size: ${theme.font.sizes.medium};
  `}
  left: 20px;
  top: 100px;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const ProductInformation = styled(Box)`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    z-index: ${theme.layers.modal};
    min-height: 10rem;
    min-width: 30rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    small {
      color: ${theme.colors.darkGray};
      font-weight: ${theme.font.normal};
    }
  `}
`;

export const Divider = styled(CustomDivider)`
  margin: 15px 0;
`;

export const RadioWrapper = styled.div`
  display: flex;
  width: 87vw;
  justify-content: space-between;
  margin-top: -4px;

  > div {
    max-width: 40%;
  }
`;

export const Description = styled.span``;

export const ActionWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 3rem;
  background-color: ${({ theme }) => theme.colors.white};
  bottom: 0;
  width: 100%;

  ${({ theme }) => css`
    padding: 0 ${theme.spacings.small};
    padding-top: ${theme.spacings.xsmall};
    padding-bottom: ${theme.spacings.xsmall};
    z-index: ${theme.layers.alwaysOnTop};
  `}
  box-shadow: 0px -5px 12px rgba(0, 0, 0, 0.1);
`;

export const ComplementHeader = styled.div`
  display: flex;
  align-items: center;
  > span {
    ${({ theme }) => css`
      border-radius: ${theme.border.radius.normal};
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
      text-align: center;
      font-size: ${theme.font.sizes.xsmall};
      font-weight: ${theme.font.bold};
      align-items: center;
      display: flex;
      padding: ${theme.spacings.xxsmall};
    `}
  }
`;

export const ImageShow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 90px;

  img {
    width: 300px;
    border-radius: 9px;
    box-shadow: 0px -5px 12px rgba(0, 0, 0, 0.1);
  }
`;

export const BoxImage = styled.div`
  height: auto;
  max-height: 400px;
  overflow: hidden;
  border-radius: 9px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ObsBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ObsInformation = styled(Box)`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    z-index: ${theme.layers.modal};
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 0;
    small {
      color: #66707d;
      font-weight: ${theme.font.normal};
    }
    .ant-form-item {
      margin: 15px 0 5px 0;
    }

    textarea {
      border-color: #e6ecf2;
      resize: none;
      &:hover {
        border-color: #e6ecf2;
      }

      &:focus {
        border-color: transparent;
      }
    }
  `}
`;
