import React from 'react';
import Button from 'stories/general/Button';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootType } from 'store/modules/rootReducer';
import Fireworks from 'assets/images/fireworks.svg';
import * as S from './styles';

const Congratulations = () => {
  const history = useHistory();
  const { origin, table, unity } = useSelector(
    (state: RootType) => state.establishment
  );
  return (
    <S.Wrapper>
      <S.Cover>
        <S.Finished>Pedido Enviado!</S.Finished>
        <img src={Fireworks}></img>
      </S.Cover>
      <small>Seu pedido foi enviado para a cozinha e será preparado.</small>
      <Button
        type="secondary"
        onClick={() => history.push(`/${origin}/${unity?.id}/${table}`)}
      >
        Voltar para ínicio
      </Button>
    </S.Wrapper>
  );
};

export default Congratulations;
