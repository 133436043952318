import { AxiosError } from 'axios';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import api from 'services/api';
import { Creators as ErrorCreators } from 'store/modules/error/actions';
import * as Response from 'utils/response';
import { UnityItemProps } from '../establishment/reducer';
import { RootType } from '../rootReducer';
import { Creators as AuthCreators, Types as AuthTypes } from './actions';

export function* setData({
  payload,
}: ReturnType<typeof AuthCreators.setDataRequest>) {
  try {
    const unity: UnityItemProps = yield select(
      (state: RootType) => state.establishment.unity
    );

    const table: number = yield select(
      (state: RootType) => state.establishment.table
    );

    if (unity && table) {
      const { status } = yield call(
        api.post,
        `menu/v2/units/${unity.id}/onboard/sign-in`,
        {
          username: payload.name,
          table: table,
        }
      );

      if (status === Response.NO_CONTENT || status === Response.HTTP_CREATED) {
        yield put(AuthCreators.setDataSuccess(payload));
      }
    }
  } catch (err) {
    const error = err as AxiosError;
    yield all([
      put(ErrorCreators.displayError(error)),
      put(AuthCreators.setDataFailed()),
    ]);
  }
}

export function* reactivate() {
  try {
    const unity: UnityItemProps = yield select(
      (state: RootType) => state.establishment.unity
    );

    const table: number = yield select(
      (state: RootType) => state.establishment.table
    );

    if (unity && table) {
      const { status } = yield call(
        api.put,
        `menu/v2/units/${unity.id}/onboard/sign-in/reactivate`
      );

      if (status === Response.NO_CONTENT || status === Response.HTTP_CREATED) {
        yield put(AuthCreators.reactivateSuccess());
      }
    }
  } catch (err) {
    const error = err as AxiosError;
    yield all([
      put(ErrorCreators.displayError(error)),
      put(AuthCreators.reactivateFailed()),
    ]);
  }
}

export default all([
  takeLatest(AuthTypes.SET_DATA_REQUEST, setData),
  takeLatest(AuthTypes.REACTIVATE_REQUEST, reactivate),
]);
