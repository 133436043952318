import { Title } from '@getinapp/appetizer';
import sendCart from 'assets/images/send_cart.svg';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Creators as AuthCreators } from 'store/modules/auth/actions';
import Button from 'stories/general/Button';
import * as S from './styles';

const Success = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    return () => {
      dispatch(AuthCreators.reactivateRequest());
    };
  });

  return (
    <S.Wrapper>
      <img src={sendCart} alt="Confetes" />
      <S.SuccessBox>
        <Title level={2}>Pedido Enviado!</Title>
      </S.SuccessBox>
      <p>Seu pedido foi enviado para a cozinha e será preparado.</p>
      <Button style={{ width: '300px' }} onClick={() => history.push('/')}>
        Voltar para o início
      </Button>
    </S.Wrapper>
  );
};

export default Success;
