import { message } from 'antd';
import { all, fork, put, select } from 'redux-saga/effects';
import { Creators as EstablishmentCreators } from 'store/modules/establishment/actions';
import auth from './auth/sagas';
import billing from './billing/sagas';
import cart from './cart/sagas';
import category from './category/sagas';
import error from './error/sagas';
import unity from './establishment/sagas';
import menu from './menu/sagas';
import order from './order/sagas';
import product from './product/sagas';
import { RootType } from './rootReducer';

function* INITIAL_SAGA() {
  const { router, establishment } = yield select((state: RootType) => state);
  const [, origin, unity, table] = router.location.pathname.split('/');
  const { t: token } = router.location.query;

  if (unity || establishment.unity || sessionStorage.getItem('unity')) {
    sessionStorage.setItem('unity', unity || sessionStorage.getItem('unity'));
    sessionStorage.setItem('table', table || sessionStorage.getItem('table'));

    if (token || sessionStorage.getItem('token')) {
      sessionStorage.setItem('token', token || sessionStorage.getItem('token'));
    }
    yield all([
      put(
        EstablishmentCreators.setInformations({
          origin,
          token: token || sessionStorage.getItem('token'),
          table: table || sessionStorage.getItem('table'),
        })
      ),
      put(
        EstablishmentCreators.getUnityRequest(
          unity || sessionStorage.getItem('unity')
        )
      ),
    ]);
  } else {
    message.error({
      content: 'Nenhuma unidade foi informada.',
      style: {
        marginTop: 60,
      },
    });
  }
}

export default function* rootSaga(): Generator {
  return yield all([
    fork(INITIAL_SAGA),
    unity,
    menu,
    category,
    product,
    order,
    billing,
    error,
    auth,
    cart,
  ]);
}
