import { Skeleton } from 'antd';
import Box from 'stories/general/Box';
import styled, { css } from 'styled-components';

export const Loading = styled(Skeleton.Input)`
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;

  ${({ theme }) => css`
    padding: 10rem ${theme.spacings.xsmall};
  `}
`;

export const UnityInformations = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const About = styled.div`
  display: flex;
  align-items: center;

  > div {
    ${({ theme }) => css`
      margin-left: ${theme.spacings.xxsmall};
    `}
  }
`;

export const MenuWrapper = styled(Box)`
  border-radius: 0.8rem;

  &:hover {
    cursor: pointer;
  }
`;

export const Table = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  ${({ theme }) => css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.secondary};
    border-radius: ${theme.border.radius.normal};
    padding: 0 10px;

    small {
      font-size: 1rem;
    }

    h2 {
      color: ${theme.colors.white};
    }
  `}
`;
