import Layout from 'components/Layout';
import Bill from 'pages/Bill';
import Cart from 'pages/Cart';
import Congratulations from 'pages/Congratulations';
import Error401 from 'pages/errors/401';
import Home from 'pages/Home';
import Menu from 'pages/Menu';
import Product from 'pages/Product';
import Search from 'pages/Search';
import Success from 'pages/Success';
import Welcome from 'pages/Welcome';
import { Route, Router, Switch } from 'react-router-dom';
import { history } from 'routes/history';

const Routes = () => (
  <Router history={history}>
    <Switch>
      <Route path="/welcome" exact component={Welcome} />
      <Route path="/401" exact component={Error401} />

      <Layout>
        <Route path="/congratulations" exact component={Congratulations} />
        <Route path="/:origin/:unity/:table" exact component={Home} />
        <Route path="/" exact component={Home} />

        <Route path="/cart" exact component={Cart} />
        <Route path="/bill" exact component={Bill} />

        <Route
          path="/:origin/:unity/:table/search/:term"
          exact
          component={Search}
        />
        <Route path="/:origin/:unity/:table/:menu" exact component={Menu} />
        <Route
          path="/:origin/:unity/:table/product/:product"
          exact
          component={Product}
        />

        <Route path="/success" exact component={Success} />
      </Layout>
    </Switch>
  </Router>
);
export default Routes;
