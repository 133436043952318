import React, { useEffect } from 'react';
import Space from 'stories/utils/Space';
import { useParams, useHistory } from 'react-router-dom';
import { Title } from 'stories/typography';
import Product from 'components/Product';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as CategoryCreators } from 'store/modules/category/actions';
import { RootType } from 'store/modules/rootReducer';
import FinishOrder from 'components/FinishOrder';
import { InView } from 'react-intersection-observer';

import * as S from './styles';

type ParamProps = { menu: string };

const Menu = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { menu } = useParams<ParamProps>();
  const {
    establishment: {
      unity,
      hide_price_enabled: isHidingPrice,
      table,
      token,
      order_enabled: isIntegratedRestaurant,
    },
    category: { data: categories, loading },
  } = useSelector((state: RootType) => state);

  const isShowPrice = !isHidingPrice || (!!token && isIntegratedRestaurant);

  useEffect(() => {
    if (unity) {
      dispatch(CategoryCreators.getCategoriesRequest(menu));
    }
  }, [unity, dispatch, menu]);

  function handleChangeCategory(categoryId: string) {
    dispatch(CategoryCreators.getCategoriesInviewMenu(categoryId));
  }

  return (
    <>
      <S.Wrapper>
        <Space direction="vertical" size={15}>
          <Space direction="vertical" size={30}>
            {!loading &&
              categories.map((category) => (
                <InView
                  key={category.id}
                  threshold={0.75}
                  onChange={(e) => {
                    if (e) {
                      handleChangeCategory(category.id);
                    }
                  }}
                >
                  <S.CategorySection key={category.id}>
                    <Space direction="vertical" size={15}>
                      <div id={category.title}>
                        <Title level={2}>{category.title}</Title>
                      </div>
                      <S.ProductList>
                        {category.items.map((product) => (
                          <Product
                            key={product.id}
                            title={product.title}
                            description={product.description}
                            price={product.price}
                            image={product.images[0]?.url || null}
                            onClick={() =>
                              history.push(
                                `/store/${unity?.id}/${table}/product/${product.id}`
                              )
                            }
                            showPrice={isShowPrice}
                          />
                        ))}
                      </S.ProductList>
                    </Space>
                  </S.CategorySection>
                </InView>
              ))}
          </Space>
        </Space>
      </S.Wrapper>
      <FinishOrder />
    </>
  );
};

export default Menu;
