import { Modal } from 'antd';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  ${({ theme }) => css`
    > div {
      padding: 0 ${theme.spacings.xsmall};
    }
    padding: 10rem 0;
  `}
`;

export const ProductList = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    margin-top: ${theme.spacings.xxsmall};
    border-radius: ${theme.border.radius.normal};
  `}
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

export const AddItens = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.xsmall};
  `}
  button {
    background-color: transparent;
  }

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Finish = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.small};

    > div {
      width: 45%;
      p {
        font-size: ${theme.font.sizes.xsmall};
      }
    }
  `}
  button {
    margin: 0.5rem 0;
    padding: 0;
  }

  position: fixed;
  bottom: 0;
  background: white;
  width: 100%;
  height: 17.5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  text-align: left;

  box-shadow: 0px -5px 12px rgba(0, 0, 0, 0.1);
`;

export const TableOrders = styled.div`
  ${({ theme }) => css`
    h2 {
      margin-bottom: ${theme.spacings.xxsmall};
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    padding: 0 0;
  `}
`;

export const ProductFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.xxsmall};

    button:focus {
      background: ${theme.colors.primary};
      outline: none;
      box-shadow: none;
    }

    button:active {
      opacity: 1;
      background-color: ${theme.colors.primary};
    }
  `}
  > div {
    width: 40%;
    display: flex;
    align-items: center;
    h5 {
      ${({ theme }) => css`
        margin-left: ${theme.spacings.xsmall};
      `}
    }
  }
`;

export const Remove = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-size: ${theme.font.sizes.small};
    margin-left: ${theme.spacings.xxxsmall};
  `}
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px 0;
  flex-direction: column;
  button {
    width: 100%;
    margin: 2px 0;
  }
`;

export const ModalCustom = styled(Modal)`
  .ant-modal-header {
    padding: 0px 24px;
    border-bottom: 0;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .ant-modal-content,
  .ant-modal-close {
    ${({ theme }) => css`
      padding: 0px 10px;
      padding-top: 15px;

      border-radius: ${theme.border.radius.large};
    `}
  }

  .ant-modal-body {
    padding: 10px 23px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .ant-modal-title {
    text-align: center;
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => css`
    svg {
      color: ${theme.colors.white};
    }
  `}
`;

export const FooterConfirm = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.small};
  `}
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 1;
  background: #fff;
  padding: 15px 15px 30px;

  h5 {
    color: #dd4f50;
    margin: 5px 0 0 0;
  }

  strong {
    color: #dd4f50;
  }

  p {
    font-size: 15px;
    line-height: 20px;
    margin: 20px 0;
  }
  box-shadow: 0px -5px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px 12px 0px 0px;
`;

export const CloseBox = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  margin-top: 15px;
`;
