import { collection, query as Query } from '@firebase/firestore';
import { useFirestoreQuery } from '@react-query-firebase/firestore';
import { where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { firestore } from 'services/firebase';
import { Creators as CartCreators } from 'store/modules/cart/actions';
import { RootType } from 'store/modules/rootReducer';
import Avatar from 'stories/display/Avatar';
import Button from 'stories/general/Button';
import { Title } from 'stories/typography';
import Space from 'stories/utils/Space';
import * as S from './styles';

const getInitials = (name: string) => {
  const names = name.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

const ConnectedPeoples = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    establishment: { unity, table },
    cart: { status },
  } = useSelector((state: RootType) => state);
  const location = useLocation<{ product: string }>();
  const [load, setLoad] = useState(false);

  const id = `${unity?.id}`;
  const tableNumber = `${table}`;

  useEffect(() => {
    setLoad(true);
    dispatch(CartCreators.loadCartStatusRequest());
  }, [dispatch, load, location]);

  const ref = Query(
    collection(firestore, id, tableNumber, 'users'),
    where('status', '!=', 'idle')
  );

  const query = useFirestoreQuery(
    ['tables'],
    ref,
    { subscribe: true },
    {
      cacheTime: 0,
    }
  );

  if (
    location.pathname.match(/product/gm) ||
    location.pathname.match(/cart/gm)
  ) {
    return null;
  }

  const users = query?.data?.docs || [];

  return (
    <S.Wrapper>
      <Title level={3}>{users.length} pessoas conectadas a mesa</Title>
      <br />
      {status == 'has_items' ? (
        <S.AvatarsWrapper>
          <div>
            <Space size={5} direction="horizontal">
              {users.map((snapshot) => {
                const user = snapshot.data();

                return (
                  <Avatar size={40} key={snapshot.id}>
                    <Title level={5}>{getInitials(user.username)}</Title>
                  </Avatar>
                );
              })}
            </Space>
          </div>
          &nbsp; &nbsp;
          {status == 'has_items' && (
            <Button onClick={() => history.push('/cart')}>
              Ver meu pedido
            </Button>
          )}
        </S.AvatarsWrapper>
      ) : (
        <div>
          <Space size={5} direction="horizontal">
            {users.map((snapshot) => {
              const user = snapshot.data();

              return (
                <Avatar size={40} key={snapshot.id}>
                  <Title level={5}>{getInitials(user.username)}</Title>
                </Avatar>
              );
            })}
          </Space>
        </div>
      )}
    </S.Wrapper>
  );
};

export default ConnectedPeoples;
