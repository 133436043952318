import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    > div {
      padding: 0 ${theme.spacings.xsmall};
    }
    padding: 10rem 0;
  `}
`;

export const ProductList = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    padding: 0 ${theme.spacings.xsmall};
    margin-top: ${theme.spacings.xxsmall};
    border-radius: ${theme.border.radius.normal};
  `}
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

export const NoResult = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
`;
