import axios from 'axios';

const betaDomain = 'menu-beta.getinapp.com.br';
const isBetaDomain = window.location.hostname === betaDomain ? true : false;

const api = axios.create({
  baseURL: isBetaDomain
    ? process.env.REACT_APP_BETA_BASE_URL
    : process.env.REACT_APP_BASE_URL,
});

api.defaults.headers = {
  'content-type': 'application/json',
  Accept: 'application/json',
};

api.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('token');
  const userId = localStorage.getItem('user-id');
  const isIntegratedRestaurant = sessionStorage.getItem(
    'isIntegratedRestaurant'
  );
  const hasTokenInUrl = window.location.href.includes('?t=');

  if (config.headers && token) {
    config.headers['X-Token'] = token;
    config.headers['X-User-Identifier'] = userId || '';

    if (isIntegratedRestaurant === 'false') {
      if (!hasTokenInUrl) {
        delete config.headers?.['X-Token'];
      } else {
        config.headers['X-Token'] = token;
      }
    }
  }

  return config;
});

export default api;
