import produce from 'immer';
import { Types as EstablishmentTypes } from './actions';

export type UnityItemProps = {
  id: string;
  name: string;
  profile_image: string;
};

export type EstablishmentProps = {
  loading: boolean;
  table: number | null;
  token: string | null;
  origin: string | null;
  order_enabled: boolean;
  hide_price_enabled: boolean;
  unity: UnityItemProps | null;
};

export const INITIAL_STATE: EstablishmentProps = {
  loading: false,
  table: null,
  token: null,
  origin: null,
  unity: null,
  order_enabled: false,
  hide_price_enabled: false,
};

const establishment = produce((draft: EstablishmentProps, action) => {
  switch (action.type) {
    case EstablishmentTypes.GET_UNITY_REQUEST:
      draft.loading = true;
      break;
    case EstablishmentTypes.GET_UNITY_SUCCESS:
      draft.loading = false;
      draft.unity = action.payload;
      draft.order_enabled = action.payload.order_enabled;
      draft.hide_price_enabled = action.payload.hide_price_enabled;
      break;
    case EstablishmentTypes.GET_UNITY_FAILED:
      draft.loading = false;
      break;
    case EstablishmentTypes.SET_INFORMATIONS:
      draft.token = action.payload.token;
      draft.origin = action.payload.origin;
      draft.table = action.payload.table;
      break;
  }
}, INITIAL_STATE);

export default establishment;
