import styled, { css } from 'styled-components';

export const ProductList = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    padding: 0 ${theme.spacings.xsmall};
    border-radius: ${theme.border.radius.normal};
  `}
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

export const CategorySection = styled.section`
  ${({ theme }) => css`
    h2 {
      padding: 0 ${theme.spacings.xsmall};
    }
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.large};
    margin-top: 15rem;
  `}
`;
