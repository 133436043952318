import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
  `}
  width: 100%;
  position: fixed;
  padding: 1.5rem 1rem;
  align-items: center;
  justify-content: center;
  bottom: 0;
`;

export const Order = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    padding: ${theme.spacings.xxsmall};
    border-radius: ${theme.border.radius.normal};
    b {
      color: ${theme.colors.white};
    }

    span {
      border-radius: 50%;
      background-color: ${theme.colors.white};
      width: 25px;
      text-align: center;
      font-weight: bold;
    }
  `}
`;
