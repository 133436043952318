import { AppetizerProvider } from '@getinapp/appetizer';
import * as Sentry from '@sentry/react';
import { ConfigProvider } from 'antd';
import locale from 'antd/lib/locale/pt_BR';
import 'configs/reactotron';
import { ConnectedRouter } from 'connected-react-router';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Routes from 'routes';
import { history } from 'routes/history';
import { persistor, store } from 'store';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from 'styles/global';
import theme from 'styles/theme';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
});

const queryClient = new QueryClient();

const App = () => (
  <ConfigProvider locale={locale}>
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
            <QueryClientProvider client={queryClient}>
              <AppetizerProvider>
                <ThemeProvider theme={theme}>
                  <GlobalStyles />
                  <Routes />
                </ThemeProvider>
              </AppetizerProvider>
            </QueryClientProvider>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </Sentry.ErrorBoundary>
  </ConfigProvider>
);

export default App;
