import { HeartFill } from '@styled-icons/bootstrap/HeartFill';
import logo from 'assets/images/logo-white.svg';
import { DOWNLOAD_APP_LINK } from 'utils/constants';
import { Event } from 'utils/helpers';
import * as S from './styles';

const Footer = () => {
  const handleDownloadApp = () => {
    Event.push('getin_download_app_menu');
    window.open(DOWNLOAD_APP_LINK, '__blank');
  };

  return (
    <S.Footer>
      <S.LogoWrapper>
        Feito com <HeartFill color="red" size={12} /> por{' '}
        <img src={logo} alt="Logotipo com os dizeres: Get In" width="65" />
      </S.LogoWrapper>

      <S.DownloadButton
        variant="outline"
        size="regular"
        onClick={handleDownloadApp}
      >
        Baixe o app
      </S.DownloadButton>
    </S.Footer>
  );
};

export default Footer;
