import * as Response from 'utils/response';
import { Creators as ErrorCreators } from 'store/modules/error/actions';

import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import {
  Types as CategoryTypes,
  Creators as CategoryCreators,
} from './actions';
import api from 'services/api';
import { UnityItemProps } from '../establishment/reducer';
import { RootType } from '../rootReducer';
import { AxiosError } from 'axios';

export function* getCategories(
  action: ReturnType<typeof CategoryCreators.getCategoriesRequest>
) {
  try {
    const unity: UnityItemProps = yield select(
      (state: RootType) => state.establishment.unity
    );
    if (unity) {
      const { status, data: response } = yield call(
        api.get,
        `menu/v1/units/${unity.id}/menus/${action.payload}/categories?pagination=0`
      );

      if (status === Response.HTTP_OK) {
        yield put(CategoryCreators.getCategoriesSuccess(response.data));
      }
    }
  } catch (err) {
    const error = err as AxiosError;

    yield all([
      put(ErrorCreators.displayError(error)),
      put(CategoryCreators.getCategoriesFailed()),
    ]);
  }
}

export default all([
  takeLatest(CategoryTypes.GET_CATEGORIES_REQUEST, getCategories),
]);
