import { Checkbox, Divider, Form, Radio, Skeleton } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import OrderEnabled from 'components/OrderEnabled';
import Quantity from 'components/Quantity';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Creators as OrderCreators } from 'store/modules/order/actions';
import { Creators as ProductCreators } from 'store/modules/product/actions';
import { RootType } from 'store/modules/rootReducer';
import { TextArea } from 'stories/entry';
import Button from 'stories/general/Button';
import { Title } from 'stories/typography';
import Space from 'stories/utils/Space';
import { getCurrency } from 'utils/helpers';
import * as S from './styles';

const styles = {
  group: { width: '100%', padding: '10px' },
  radio: {
    display: 'flex',
    alignItems: 'top',
    width: '100%',
  },
};

type ParamProps = {
  product: string;
};

const Product = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { product: id } = useParams<ParamProps>();
  const [observation, setObservation] = useState<string>();
  const [quantity, setQuantity] = useState(1);
  const [complements, setComplements] = useState<
    Array<{
      id: string;
      title: string;
      type: string;
      options: Array<{
        id: CheckboxValueType;
        title: string;
        quantity: number | string;
        price?: number;
      }>;
    }>
  >([]);
  const [productPrice, setProductPrice] = useState<number>(0);
  const {
    establishment: {
      unity,
      token,
      order_enabled,
      hide_price_enabled: isHidingPrice,
    },
    product: { data: product, loading },
  } = useSelector((state: RootType) => state);

  const isDigitalMenu = !token;
  const isIntegratedMenu = !!token;

  const shouldShowObservations = order_enabled && !!token;

  const shouldShowOrderOptions = isIntegratedMenu && order_enabled;
  const [shouldShowProductPrice, setShouldShowProductPrice] =
    useState<boolean>(false);

  useEffect(() => {
    if (isDigitalMenu) {
      setShouldShowProductPrice(!isHidingPrice);
    } else if (isIntegratedMenu) {
      setShouldShowProductPrice(true);
    }
  }, [isDigitalMenu, isHidingPrice, isIntegratedMenu]);

  useEffect(() => {
    if (unity) dispatch(ProductCreators.getProductRequest(id));
  }, [id, dispatch, unity]);

  useEffect(() => {
    if (product) setProductPrice(product.price);
  }, [product]);

  const getPrice = (price: number) =>
    getCurrency(price).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

  const handleAddProductToCart = () => {
    if (product) {
      const options: Array<{
        id: CheckboxValueType;
        quantity: number | string;
      }> = [];

      if (complements.length > 0) {
        complements.map((obj) => {
          return obj.options.map((option) => {
            options.push({
              id: option.id,
              quantity: option.quantity,
            });
          });
        });
      }

      dispatch(
        OrderCreators.addItemRequest({
          id: product.id,
          quantity,
          complements: options,
          observation,
        })
      );

      setQuantity(0);
    }
  };

  const handleCheckbox = (id: string, values: CheckboxValueType[]) => {
    let optionals = [...complements];
    if (values.length > 0) {
      const complement = product?.complements.find(
        (complement) => complement.id === id
      );

      optionals = [
        ...complements.filter((complement) => complement.id !== id),
        {
          id: id,
          title: complement?.title || '',
          type: complement?.type || '',
          options: values.map((value) => {
            const option = complement?.options.find(
              (option) => option.id === value
            );

            return {
              id: value,
              title: option?.title || '',
              price: option?.price,
              quantity: 1,
            };
          }),
        },
      ];
    } else {
      optionals = complements.filter((complement) => complement.id !== id);
    }

    let newPrice = 0;
    optionals.map((optional) => {
      optional.options.map((option) => {
        newPrice += option.price || 0;
      });
    });

    setProductPrice(newPrice + (product?.price || 0));
    setComplements(optionals);
  };

  const handleRadioGroup = (complement_id: string, option_id: string) => {
    const optional = product?.complements.find(
      (complement) => complement.id === complement_id
    );

    let optionals = complements.filter(
      (complement) => complement.id !== complement_id
    );

    const option = optional?.options.find((option) => option.id === option_id);

    optionals = [
      ...optionals,
      {
        id: complement_id,
        title: optional?.title || '',
        type: optional?.type || '',

        options: [
          {
            id: option_id,
            title: option?.title || '',
            price: option?.price,
            quantity: 1,
          },
        ],
      },
    ];

    let newPrice = 0;
    optionals.map((optional) => {
      optional.options.map((option) => {
        newPrice += option.price || 0;
      });
    });

    setProductPrice(newPrice + (product?.price || 0));
    setComplements(optionals);
  };

  const checkDisabled = (): boolean => {
    let disabled = false;

    product?.complements.map((complement) => {
      const finded = complements.find(
        (optional) => optional.id === complement.id
      );
      if (complement.required) {
        if (!finded) {
          disabled = true;
        }
      }

      if (
        finded &&
        finded?.options.length > complement.limits.max &&
        finded?.type != 'single'
      ) {
        disabled = true;
      }
      if (
        finded &&
        finded?.options.length < complement.limits.min &&
        finded?.type != 'single'
      ) {
        disabled = true;
      }
      if (finded && finded?.type == 'single' && finded?.options.length > 1) {
        disabled = true;
      }
    });
    if (!product) disabled = true;

    if (quantity === 0) disabled = true;

    return disabled;
  };

  return (
    <>
      <S.Wrapper>
        <Space direction="vertical" size={15}>
          <S.Back onClick={() => history.goBack()}>{'<'} Voltar</S.Back>
          <S.ImageShow>
            {product?.images[0]?.url && (
              <S.BoxImage>
                <img
                  src={product?.images[0]?.url || ''}
                  alt="Imagem do produto"
                />
              </S.BoxImage>
            )}
          </S.ImageShow>
          <S.ProductBox>
            {product ? (
              <S.ProductInformation>
                <Title level={2}>{product?.title}</Title>
                <small>{product?.description}</small>
              </S.ProductInformation>
            ) : (
              <S.ProductInformation>
                <Skeleton active paragraph={{ rows: 2 }} />
              </S.ProductInformation>
            )}
          </S.ProductBox>
          <Space direction="vertical" size={30}>
            {product &&
              product.complements.length > 0 &&
              product.complements.map((complement) => (
                <S.CategorySection key={complement.id}>
                  <Space direction="vertical" size={15}>
                    <S.ComplementHeader>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Title level={2}>{complement.title}</Title>
                      </div>
                      {shouldShowOrderOptions && (
                        <>
                          {complement.limits.max > 0 &&
                            complement.limits.min > 0 &&
                            complement.limits.min !== complement.limits.max && (
                              <small style={{ marginRight: '1rem' }}>
                                Escolha de {complement.limits.min} até{' '}
                                {complement.limits.max}{' '}
                                {complement.limits.max > 1 ? 'opções' : 'opção'}
                                .
                              </small>
                            )}
                          {complement.limits.min > 0 &&
                            complement.limits.min === complement.limits.max && (
                              <small style={{ marginRight: '1rem' }}>
                                Escolha {complement.limits.max}{' '}
                                {complement.limits.max > 1 ? 'opções' : 'opção'}
                                .
                              </small>
                            )}
                          <OrderEnabled>
                            {complement.required && <span>Obrigatório</span>}
                          </OrderEnabled>
                        </>
                      )}
                    </S.ComplementHeader>
                    <S.ProductList>
                      {shouldShowOrderOptions ? (
                        complement.limits.max === 1 ? (
                          <Radio.Group
                            onChange={(e) =>
                              handleRadioGroup(complement.id, e.target.value)
                            }
                            style={styles.group}
                          >
                            {complement.options.map((option, index) => (
                              <div key={option.id}>
                                {!order_enabled ? (
                                  <S.RadioWrapper>
                                    <div>
                                      <Title level={6}>{option.title}</Title>
                                    </div>
                                    $
                                    {shouldShowProductPrice && (
                                      <b>{getPrice(option.price)}</b>
                                    )}
                                  </S.RadioWrapper>
                                ) : (
                                  <Radio
                                    key={option.id}
                                    style={styles.radio}
                                    value={option.id}
                                  >
                                    <S.RadioWrapper>
                                      <div>
                                        <Title level={6}>{option.title}</Title>
                                      </div>
                                      {shouldShowProductPrice && (
                                        <b>{getPrice(option.price)}</b>
                                      )}
                                    </S.RadioWrapper>
                                  </Radio>
                                )}
                                {index !== complement.options.length - 1 && (
                                  <Divider />
                                )}
                              </div>
                            ))}
                          </Radio.Group>
                        ) : (
                          <Checkbox.Group
                            key={complement.id}
                            style={styles.group}
                            onChange={(values) =>
                              handleCheckbox(complement.id, values)
                            }
                          >
                            {complement.options.map((option, index) => (
                              <div key={option.id}>
                                <div
                                  style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  {order_enabled ? (
                                    <Checkbox value={option.id}>
                                      {option.title}
                                    </Checkbox>
                                  ) : (
                                    <Title level={6}>{option.title}</Title>
                                  )}
                                  {shouldShowProductPrice && (
                                    <b>{getPrice(option.price)}</b>
                                  )}
                                </div>
                                {index !== complement.options.length - 1 && (
                                  <Divider />
                                )}
                              </div>
                            ))}
                          </Checkbox.Group>
                        )
                      ) : complement.limits.max === 1 ? (
                        <div style={styles.group}>
                          {complement.options.map((option, index) => (
                            <div key={option.id}>
                              <S.RadioWrapper>
                                <div>
                                  <Title level={6}>{option.title}</Title>
                                </div>
                                {shouldShowProductPrice && (
                                  <b>{getPrice(option.price)}</b>
                                )}
                              </S.RadioWrapper>

                              {index !== complement.options.length - 1 && (
                                <Divider />
                              )}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div key={complement.id} style={styles.group}>
                          {complement.options.map((option, index) => (
                            <div key={option.id}>
                              <div
                                style={{
                                  display: 'flex',
                                  width: '100%',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Title level={6}>{option.title}</Title>
                                {shouldShowProductPrice && (
                                  <b>{getPrice(option.price)}</b>
                                )}
                              </div>
                              {index !== complement.options.length - 1 && (
                                <Divider />
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </S.ProductList>
                  </Space>
                </S.CategorySection>
              ))}
          </Space>
          {shouldShowObservations && (
            <Space direction="vertical" size={30}>
              <S.ObsBox>
                <S.ObsInformation>
                  <Title level={4}>Observações</Title>
                  <Form.Item name="observation">
                    <TextArea
                      rows={3}
                      onChange={(value) => setObservation(value.target.value)}
                      maxLength={140}
                    />
                  </Form.Item>
                  <small>
                    Pedido sujeito a disponibilidade do restaurante.
                  </small>
                </S.ObsInformation>
              </S.ObsBox>
            </Space>
          )}
        </Space>
      </S.Wrapper>
      {shouldShowOrderOptions && (
        <OrderEnabled>
          <S.ActionWrapper>
            <Quantity
              value={quantity}
              onChange={(value) => setQuantity(value)}
            />
            <Button disabled={checkDisabled()} onClick={handleAddProductToCart}>
              {!loading
                ? `Adicionar ${getPrice(productPrice * quantity)}`
                : `Adicionar R$ 0,00`}
            </Button>
          </S.ActionWrapper>
        </OrderEnabled>
      )}
    </>
  );
};

export default Product;
