import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import reducers from 'store/modules/rootReducer';
import sagas from 'store/modules/rootSaga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

import { history } from 'routes/history';

const persistConfig = {
  key: 'root',
  timeout: 0,
  storage,
  whitelist: ['cart'],
};

const middlewares = [];

const sagaMonitor =
  process.env.NODE_ENV === 'development'
    ? console.tron.createSagaMonitor()
    : null;

const sagaMiddleware = createSagaMiddleware({
  sagaMonitor,
});

middlewares.push(sagaMiddleware);
middlewares.push(routerMiddleware(history));

const composer =
  process.env.NODE_ENV === 'development'
    ? compose(applyMiddleware(...middlewares), console.tron.createEnhancer())
    : compose(applyMiddleware(...middlewares));

const persistedReducer = persistReducer(persistConfig, reducers(history));

const store = createStore(persistedReducer, composer);

sagaMiddleware.run(sagas);

const persistor = persistStore(store);

export { store, persistor };
