import produce from 'immer';
import { Types as AuthTypes } from './actions';

export type AuthProps = {
  name: string | null;
  loading: boolean;
};

export const INITIAL_STATE: AuthProps = {
  name: null,
  loading: false,
};

const auth = produce((draft: AuthProps, action) => {
  switch (action.type) {
    case AuthTypes.SET_DATA_REQUEST:
      draft.loading = true;
      break;
    case AuthTypes.SET_DATA_SUCCESS:
      draft.name = action.payload.name;
      draft.loading = false;
      break;
    case AuthTypes.SET_DATA_FAILED:
      draft.name = '';
      draft.loading = false;
      break;

    case AuthTypes.REACTIVATE_SUCCESS:
      draft.loading = false;
      break;

    case AuthTypes.REACTIVATE_REQUEST:
      draft.loading = true;
      break;

    case AuthTypes.REACTIVATE_FAILED:
      draft.loading = false;
      break;
  }
}, INITIAL_STATE);

export default auth;
