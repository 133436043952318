import { ActionCreators, createActions } from 'reduxsauce';

export type ComplementsProps = {
  id: string | number | boolean;
  quantity: number | string;
};

export type ItemOrderProp = {
  id: string;
  quantity: number;
  complements: Array<ComplementsProps>;
  observation?: string;
};

export enum Types {
  SEND_ORDER_REQUEST = '@order/SEND_ORDER_REQUEST',
  SEND_ORDER_SUCCESS = '@order/SEND_ORDER_SUCCESS',
  SEND_ORDER_FAILED = '@order/SEND_ORDER_FAILED',

  ADD_ITEM_REQUEST = '@order/ADD_ITEM_REQUEST',
  ADD_ITEM_SUCCESS = '@order/ADD_ITEM_SUCCESS',
  ADD_ITEM_FAILED = '@order/ADD_ITEM_FAILED',

  CLEAR_ORDER_OWNER = '@order/CLEAR_ORDER_OWNER',
}

interface Actions extends ActionCreators {
  sendOrderRequest: () => {
    type: Types.SEND_ORDER_REQUEST;
  };
  sendOrderSuccess: () => {
    type: Types.SEND_ORDER_SUCCESS;
  };
  sendOrderFailed: () => {
    type: Types.SEND_ORDER_FAILED;
  };

  addItemRequest: (payload: ItemOrderProp) => {
    type: Types.ADD_ITEM_REQUEST;
    payload: ItemOrderProp;
  };
  addItemSuccess: (payload: string) => {
    type: Types.ADD_ITEM_SUCCESS;
  };
  addItemFailed: () => {
    type: Types.ADD_ITEM_REQUEST;
  };
  clearOrderOwner: () => {
    type: Types.CLEAR_ORDER_OWNER;
  };
}

const CreatedActions = createActions(
  {
    sendOrderRequest: ['payload'],
    sendOrderSuccess: ['payload'],
    sendOrderFailed: [],

    addItemRequest: ['payload'],
    addItemSuccess: ['payload'],
    addItemFailed: [],
    clearOrderOwner: [],
  },
  {
    prefix: '@order/',
  }
);

export const Creators = CreatedActions.Creators as Actions;
