import produce from 'immer';
import { Types as ProductTypes } from './actions';

export type ProductItemProps = {
  id: string;
  title: string;
  description: string;
  price: number;
  images: Array<{ url: string }>;

  complements: Array<{
    id: string;
    title: string;
    required: boolean;
    description: string;
    type?: string;
    limits: {
      min: number;
      max: number;
    };
    options: Array<{ id: string; title: string; price: number }>;
  }>;
};

export type ProductProps = {
  loading: boolean;
  data: ProductItemProps | null;
  searching: boolean;
  term: string;
  searcheds: Array<ProductItemProps>;
};

export const INITIAL_STATE: ProductProps = {
  loading: false,
  data: null,
  searching: false,
  term: '',
  searcheds: [],
};

const product = produce((draft: ProductProps, action) => {
  switch (action.type) {
    case ProductTypes.GET_PRODUCT_REQUEST:
      draft.loading = true;
      draft.data = null;
      break;
    case ProductTypes.GET_PRODUCT_SUCCESS:
      draft.loading = false;
      draft.data = action.payload;
      break;
    case ProductTypes.GET_PRODUCT_FAILED:
      draft.loading = false;
      draft.data = null;
      break;

    case ProductTypes.SEARCH_PRODUCTS_REQUEST:
      draft.loading = true;
      draft.term = action.payload;
      break;
    case ProductTypes.SEARCH_PRODUCTS_SUCCESS:
      draft.loading = false;
      draft.searching = false;
      draft.searcheds = action.payload;
      break;
    case ProductTypes.SEARCH_PRODUCTS_FAILED:
      draft.loading = false;
      draft.searching = false;

      draft.searcheds = [];
      draft.term = '';

      break;
    case ProductTypes.OPEN_SEARCH:
      draft.searching = true;
      break;

    case ProductTypes.CLOSE_SEARCH:
      draft.searching = false;
      break;
  }
}, INITIAL_STATE);

export default product;
