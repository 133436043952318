import { ActionCreators, createActions } from 'reduxsauce';

export enum Types {
  GET_BILLING_REQUEST = '@billing/GET_BILLING_REQUEST',
  GET_BILLING_SUCCESS = '@billing/GET_BILLING_SUCCESS',
  GET_BILLING_FAILED = '@billing/GET_BILLING_FAILED',
}

interface Actions extends ActionCreators {
  getBillingSuccess: (payload: {
    paid: number;
    total: number;
    subtotal: number;
    discount: number;
    tip: number;
    data: Array<{
      id: string;
      title: string;
      quantity: number;
      price: number;
      total: number;
    }>;
  }) => {
    type: Types.GET_BILLING_SUCCESS;
    payload: {
      paid: number;
      total: number;
      subtotal: number;
      discount: number;
      tip: number;
      data: Array<{
        id: string;
        title: string;
        quantity: number;
        price: number;
        total: number;
      }>;
    };
  };
  getBillingRequest: () => {
    type: Types.GET_BILLING_REQUEST;
  };
  getBillingFailed: () => {
    type: Types.GET_BILLING_FAILED;
  };
}

const CreatedActions = createActions(
  {
    getBillingRequest: ['payload'],
    getBillingSuccess: ['payload'],
    getBillingFailed: [],
  },
  {
    prefix: '@billing/',
  }
);

export const Creators = CreatedActions.Creators as Actions;
