import produce from 'immer';
import { Types as OrderTypes } from './actions';

export type OrderProps = {
  loading: boolean;
  orderOwnerId: string;
};

export const INITIAL_STATE: OrderProps = {
  loading: false,
  orderOwnerId: '',
};

const order = produce((draft: OrderProps, action) => {
  switch (action.type) {
    case OrderTypes.SEND_ORDER_REQUEST:
      draft.loading = true;
      break;
    case OrderTypes.SEND_ORDER_SUCCESS:
      draft.loading = false;
      break;
    case OrderTypes.SEND_ORDER_FAILED:
      draft.loading = false;
      break;

    case OrderTypes.ADD_ITEM_REQUEST:
      draft.loading = true;
      break;
    case OrderTypes.ADD_ITEM_SUCCESS:
      draft.loading = false;
      draft.orderOwnerId = action.payload;
      break;
    case OrderTypes.ADD_ITEM_FAILED:
      draft.loading = false;
      draft.orderOwnerId = '';
      break;

    case OrderTypes.CLEAR_ORDER_OWNER:
      draft.orderOwnerId = '';
      break;
  }
}, INITIAL_STATE);

export default order;
