import React from 'react';
import * as S from './styles';

export type TitleProps = {
  /**
   * The sizes of title, each number respect heading styles
   */
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  /**
   * An optional icon in prefix
   */
  icon?: React.ReactNode;
  children: React.ReactNode | string;
};

/**
 * Basic text writing with heading style.
 */
const Title = ({ icon, children, level = 1, ...props }: TitleProps) => (
  <S.Title level={level} icon={icon} {...props}>
    <span>{icon}</span>
    {children}
  </S.Title>
);
export default Title;
