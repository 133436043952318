import uuid from 'uniqid';

let id = uuid();

type EventProps = {
  [key: string]: unknown;
};

export const Event = {
  push: (name: string, props?: EventProps) => {
    window.dataLayer = window.dataLayer || [];

    if (!id) {
      id = uuid();
    }

    const params = {
      event: name,
      uu_id: id,
      ...props,
    };

    window.dataLayer.push(params);
  },
};

export const getCurrency = (value: number) => value / 100;
