import { createGlobalStyle, css } from 'styled-components';
import './css/antd.css';

const GlobalStyles = createGlobalStyle`

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Poppins Light'), local('Poppins-Light'),
        url('/fonts/poppins-v12-latin-300.woff2') format('woff2'),
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Poppins Regular'), local('Poppins-Regular'),
        url('/fonts/poppins-v12-latin-regular.woff2') format('woff2'),
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
        url('/fonts/poppins-v12-latin-600.woff2') format('woff2'),
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Poppins Bold'), local('Poppins-Bold'),
      url('/fonts/poppins-v13-latin-700.woff2') format('woff2');
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    ${({ theme }) => css`
      font-family: ${theme.font.family};
    `}
  }

  ${({ theme }) => css`
    .ant-notification-notice-message {
      font-weight: ${theme.font.bold};
      font-size: ${theme.font.sizes.large};
    }

    html {
      font-size: 62.5%;
    }

    body {
      background-color: ${theme.colors.mainBg};
      font-size: ${theme.font.sizes.medium};
    }

    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 5px;
      background-color: ${theme.colors.lightGray};
    }
    ::-webkit-scrollbar {
      width: 2px;
      height: 2px;
      background-color: ${theme.colors.lightGray};
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: ${theme.colors.primary};
    }
  `}

`;

export default GlobalStyles;
