import styled, { css } from 'styled-components';

export const Wave = styled.div`
  position: absolute;
  width: 100%;
  height: 30rem;
  background: radial-gradient(
    circle at 190% -450%,
    #f2f2f2 90%,
    transparent 0.5%
  );
`;

export const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;

  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  ${({ theme }) => css`
    background-color: ${theme.colors.white};
  `}
`;

export const HeaderImage = styled.img`
  margin-right: 7.5rem;
  z-index: 10;
`;

export const FooterImage = styled.img`
  margin-left: 7.5rem;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 80%;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    small {
      ${({ theme }) => css`
        color: ${theme.colors.lightSecondary};
      `}
    }
  }
`;
