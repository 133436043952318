import { ActionCreators, createActions } from 'reduxsauce';

export enum Types {
  SET_DATA_REQUEST = '@auth/SET_DATA_REQUEST',
  SET_DATA_SUCCESS = '@auth/SET_DATA_SUCCESS',
  SET_DATA_FAILED = '@auth/SET_DATA_FAILED',

  REACTIVATE_REQUEST = '@auth/REACTIVATE_REQUEST',
  REACTIVATE_SUCCESS = '@auth/REACTIVATE_SUCCESS',
  REACTIVATE_FAILED = '@auth/REACTIVATE_FAILED',
}

interface Actions extends ActionCreators {
  setDataRequest: (payload: { name: string }) => {
    type: Types.SET_DATA_REQUEST;
    payload: { name: string };
  };

  setDataSuccess: (payload: { name: string }) => {
    type: Types.SET_DATA_SUCCESS;
    payload: { name: string };
  };

  setDataFailed: () => {
    type: Types.SET_DATA_FAILED;
  };

  reactivateRequest: () => {
    type: Types.REACTIVATE_REQUEST;
  };
  reactivateSuccess: () => {
    type: Types.REACTIVATE_SUCCESS;
  };
  reactivateFailed: () => {
    type: Types.REACTIVATE_FAILED;
  };
}

const CreatedActions = createActions(
  {
    setDataRequest: ['payload'],
    setDataSuccess: ['payload'],
    setDataFailed: [],

    reactivateRequest: [],
    reactivateSuccess: [],
    reactivateFailed: [],
  },
  {
    prefix: '@auth/',
  }
);

export const Creators = CreatedActions.Creators as Actions;
