import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  background: white;
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  box-shadow: 0px -5px 12px rgba(0, 0, 0, 0.1);
  border-radius: 16px 16px 0px 0px;
`;

export const AvatarsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;

  width: 100%;
  > div {
    max-width: 50%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
