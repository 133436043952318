/**
 * @returns {number} 200
 */
export const HTTP_OK = 200;

/**
 * @returns {number} 201
 */
export const HTTP_CREATED = 201;

/**
 * @returns {number} 202
 */
export const HTTP_ACCEPTED = 202;

/**
 * @returns {number} 204
 */
export const NO_CONTENT = 204;

/**
 * @returns {number} 400
 */
export const HTTP_BAD_REQUEST = 400;

/**
 * @returns {number} 400
 */
export const HTTP_UNAUTHORIZED = 401;

/**
 * @returns {number} 400
 */
export const NOT_FOUND = 404;
