import { AxiosError } from 'axios';
import { push } from 'connected-react-router';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import api from 'services/api';
import { Creators as CartCreators } from 'store/modules/cart/actions';
import { Creators as ErrorCreators } from 'store/modules/error/actions';
import * as Response from 'utils/response';
import { ProductItemProps } from '../product/reducer';
import { Creators as OrderCreators, Types as OrderTypes } from './actions';

export function* sendOrder() {
  try {
    const { unity, table } = yield select((state) => state.establishment);
    if (unity) {
      const items: ProductItemProps[] = yield select(
        (state) => state.cart.data
      );
      const { status } = yield call(
        api.post,
        `menu/v2/units/${unity.id}/orders`,
        {
          table,
          items,
        }
      );

      if (status === Response.NO_CONTENT) {
        yield all([
          put(push('/congratulations')),
          put(OrderCreators.sendOrderSuccess()),
          put(CartCreators.reset()),
        ]);
      }
    }
  } catch (err) {
    const error = err as AxiosError;

    yield all([
      put(ErrorCreators.displayError(error)),
      put(OrderCreators.sendOrderFailed()),
    ]);
  }
}

export function* addItem(
  action: ReturnType<typeof OrderCreators.addItemRequest>
) {
  try {
    const { unity, table } = yield select((state) => state.establishment);
    const { name } = yield select((state) => state.auth);
    if (unity) {
      const { status, data } = yield call(
        api.post,
        `menu/v2/units/${unity.id}/orders/review`,
        { table: table, username: name, items: [action.payload] }
      );

      if (status === Response.HTTP_OK || status === Response.HTTP_CREATED) {
        yield all([
          put(push('/cart')),
          put(OrderCreators.addItemSuccess(data.data.id)),
        ]);
      }
    }
  } catch (err) {
    const error = err as AxiosError;

    yield all([
      put(ErrorCreators.displayError(error)),
      put(OrderCreators.addItemFailed()),
    ]);
  }
}

export default all([
  takeLatest(OrderTypes.SEND_ORDER_REQUEST, sendOrder),
  takeLatest(OrderTypes.ADD_ITEM_REQUEST, addItem),
]);
