import * as Response from 'utils/response';
import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import { Types as ProductTypes, Creators as ProductCreators } from './actions';
import { Creators as ErrorCreators } from 'store/modules/error/actions';

import { push } from 'connected-react-router';

import api from 'services/api';
import { UnityItemProps } from '../establishment/reducer';
import { AxiosError } from 'axios';

export function* getProduct(
  action: ReturnType<typeof ProductCreators.getProductRequest>
) {
  try {
    const unity: UnityItemProps = yield select(
      (state) => state.establishment.unity
    );
    if (unity) {
      const { status, data: response } = yield call(
        api.get,
        `menu/v1/units/${unity.id}/items/${action.payload}`
      );

      if (status === Response.HTTP_OK) {
        yield put(ProductCreators.getProductSuccess(response.data));
      }
    }
  } catch (err) {
    const error = err as AxiosError;

    yield all([
      put(ErrorCreators.displayError(error)),
      put(ProductCreators.getProductFailed()),
    ]);
  }
}

export function* searchProducts(
  action: ReturnType<typeof ProductCreators.searchProductsRequest>
) {
  try {
    const unity: UnityItemProps = yield select(
      (state) => state.establishment.unity
    );
    if (unity) {
      const { status, data: response } = yield call(
        api.get,
        `menu/v1/units/${unity.id}/items?pagination=0&query=${action.payload}`
      );

      if (status === Response.HTTP_OK) {
        const { origin, table } = yield select((state) => state.establishment);

        yield all([put(ProductCreators.searchProductsSuccess(response.data))]);

        yield put(
          push(`/${origin}/${unity.id}/${table}/search/${action.payload}`)
        );
      }
    }
  } catch (err) {
    const error = err as AxiosError;

    yield all([
      put(ErrorCreators.displayError(error)),
      put(ProductCreators.searchProductsFailed()),
    ]);
  }
}

export default all([
  takeLatest(ProductTypes.GET_PRODUCT_REQUEST, getProduct),
  takeLatest(ProductTypes.SEARCH_PRODUCTS_REQUEST, searchProducts),
]);
