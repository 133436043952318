import { HeartFill } from '@styled-icons/bootstrap/HeartFill';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import { MenuOutline } from '@styled-icons/evaicons-outline/MenuOutline';
import { SearchOutline } from '@styled-icons/evaicons-outline/SearchOutline';
import logo from 'assets/images/logo-white.svg';
import Receipt from 'assets/images/receipt.svg';
import OrderEnabled from 'components/OrderEnabled';
import { useClickOutside } from 'hooks/useClickOutside';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Creators as CategoryCreators } from 'store/modules/category/actions';
import { Creators as ProductCreators } from 'store/modules/product/actions';
import { RootType } from 'store/modules/rootReducer';
import Avatar from 'stories/display/Avatar';
import { Input } from 'stories/entry';
import Button from 'stories/general/Button';
import { Small, Title } from 'stories/typography';
import Space from 'stories/utils/Space';
import { version } from '../../../package.json';
import * as S from './styles';

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [term, setTerm] = useState('');
  const [activeCategory, setActiveCategory] = useState('');
  const ref = useRef(null);
  const isMenu =
    window.location.pathname.split('/').filter((path) => path !== '').length ===
    4;

  useClickOutside(ref, () => dispatch(ProductCreators.closeSearch()));

  const {
    establishment: {
      unity,
      table,
      origin,
      token,
      order_enabled: isIntegratedRestaurant,
    },
    menu: { data },
    product: { loading, searching },
    cart: { quantity },
    category: {
      data: categories,
      loading: loadingCategories,
      categoryInView: activeCategoryId,
    },
  } = useSelector((state: RootType) => state);

  const handleSearch = () => {
    dispatch(ProductCreators.searchProductsRequest(term));
  };

  const scrollTo = useCallback(
    (id: string) => {
      const element = document.getElementById(id);
      setActiveCategory(id);
      dispatch(CategoryCreators.getCategoriesInviewMenu(id));
      element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
    [dispatch, setActiveCategory]
  );

  useEffect(() => {
    if (activeCategoryId) {
      scrollTo(activeCategoryId);
    }
  }, [activeCategoryId, scrollTo]);

  return (
    <>
      <S.Fixed>
        <S.Header>
          <S.Unity>
            <MenuOutline
              cursor="pointer"
              size={25}
              onClick={() => setVisible(true)}
            />
            <Title level={5}>{unity?.name}</Title>
          </S.Unity>
          <S.Actions>
            {token && isIntegratedRestaurant && (
              <OrderEnabled>
                <S.ClickableButton onClick={() => history.push('/bill')}>
                  <img src={Receipt} alt="Imagem do Recibo do pedido" />
                </S.ClickableButton>
              </OrderEnabled>
            )}
            <S.ClickableButton
              onClick={() => {
                if (!searching) {
                  dispatch(ProductCreators.openSearch());
                } else {
                  dispatch(ProductCreators.closeSearch());
                }
              }}
            >
              <SearchOutline size={23} color={searching ? 'red' : 'black'} />
            </S.ClickableButton>
          </S.Actions>
        </S.Header>
        {searching && (
          <S.SearchWrapper>
            <Space size={10} direction="vertical">
              <Title level={6}>Encontre um item</Title>
              <S.SearchActions>
                <Input onChange={(e) => setTerm(e.target.value)} />
                <Button
                  disabled={term.trim().length === 0}
                  loading={loading}
                  onClick={handleSearch}
                >
                  Buscar
                </Button>
              </S.SearchActions>
            </Space>
          </S.SearchWrapper>
        )}
        {isMenu && (
          <S.CategoryList>
            {!loadingCategories &&
              categories.map((category) => (
                <S.Category
                  onClick={() => scrollTo(category.title)}
                  key={category.id}
                  active={
                    category.title === activeCategory ||
                    category.id === activeCategoryId
                  }
                >
                  {category.title}
                </S.Category>
              ))}
          </S.CategoryList>
        )}
      </S.Fixed>
      <S.Drawer
        placement="left"
        closable={false}
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <div>
          <S.DrawerActions>
            <CloseOutline
              cursor="pointer"
              onClick={() => setVisible(false)}
              size={20}
            />
          </S.DrawerActions>
          <Space size={30} direction="vertical">
            <S.DrawerHeader>
              <div>
                <Space size={5} direction="vertical">
                  <Avatar size={40} src={unity?.profile_image} />
                  <Small>Seja bem-vindo!</Small>
                </Space>
                <Title level={3}>{unity?.name}</Title>
              </div>
              <OrderEnabled>
                <S.Table>
                  <small>MESA</small>
                  <Title level={2}>{table}</Title>
                </S.Table>
              </OrderEnabled>
            </S.DrawerHeader>
            <Space direction="vertical" size={20}>
              {data.map((menu) => (
                <div
                  key={menu.id}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setVisible(false);
                    history.push(`/${origin}/${unity?.id}/${table}/${menu.id}`);
                  }}
                >
                  <Title level={5}>{menu.title}</Title>
                </div>
              ))}
            </Space>
            {quantity > 0 && (
              <OrderEnabled>
                <S.Order
                  onClick={() => {
                    setVisible(false);
                    history.push('/cart');
                  }}
                >
                  <b>Pedido</b>
                  <span>{quantity}</span>
                </S.Order>
              </OrderEnabled>
            )}
          </Space>
        </div>
        <S.DrawerFooter>
          <Small>
            Feito com <HeartFill color="red" size={10} /> por{' '}
            <img src={logo} alt="Logotipo com os dizeres: Get In" width="75" />
          </Small>
          <small>{version}</small>
        </S.DrawerFooter>
      </S.Drawer>
    </>
  );
};

export default Header;
