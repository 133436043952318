import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyB0STpalGKlHpaAt3NG1pw09fPCSG7BQLY',
  authDomain: 'menu-55f76.firebaseapp.com',
  projectId: 'menu-55f76',
  storageBucket: 'menu-55f76.appspot.com',
  messagingSenderId: '778768066317',
  appId: '1:778768066317:web:14bc4237cbe76a9a857244',
  measurementId: 'G-7D2NCBZYPY',
};

const firebase = initializeApp(firebaseConfig);
const auth = getAuth(firebase);
const firestore = getFirestore();

export { firebase, auth, firestore };
