import { Layout as Wrapper } from 'antd';
import ConnectedPeoples from 'components/ConnectedPeoples';
import Footer from 'components/Footer';
import Header from 'components/Header';
import { useSelector } from 'react-redux';
import { RootType } from 'store/modules/rootReducer';
import * as S from './styles';

const Layout = ({ children }: { children: React.ReactNode }) => {
  const {
    establishment: { order_enabled, token },
  } = useSelector((state: RootType) => state);

  const shouldShowConnectedPeoples = order_enabled && !!token;

  return (
    <Wrapper>
      <Header />
      <S.Content>
        {children}
        <Footer />
        {shouldShowConnectedPeoples && <ConnectedPeoples />}
      </S.Content>
    </Wrapper>
  );
};

export default Layout;
