import { all, takeLatest, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { message } from 'antd';
import * as Response from 'utils/response';
import { Types as ErrorTypes, Creators as ErrorCreators } from './actions';
import { AxiosError } from 'axios';

export function* showError(
  action: ReturnType<typeof ErrorCreators.displayError>
) {
  const error: AxiosError<{ message: string }> = action.payload;
  if (error?.response?.data.message) {
    if (
      error?.response?.status &&
      error?.response?.status === Response.HTTP_UNAUTHORIZED
    ) {
      yield put(push('/401'));
    } else {
      message.error({
        content: error.response.data.message,
        style: {
          marginTop: 60,
        },
      });
    }
  } else if (error?.response?.status && error?.response?.status >= 500) {
    message.error({
      content:
        'Encontramos um problema no nosso servidor. Não se preocupe, nossa equipe técnica já foi notificada.',
      style: {
        marginTop: 60,
      },
    });
  } else if (error?.response?.status === Response.NOT_FOUND) {
    message.error({
      content: 'Unidade não encontrada ou desativada.',
      style: {
        marginTop: 60,
      },
    });
  } else if (error?.response?.status === Response.HTTP_UNAUTHORIZED) {
    message.error({
      content: 'O token de acesso expirou.',
      style: {
        marginTop: 60,
      },
    });
    yield put(push('/401'));
  } else {
    message.error({
      content:
        'Não foi possível conectar com o nosso servidor. Confirme se você tem uma conexão com a internet e tente novamente.',
      style: {
        marginTop: 60,
      },
    });
  }
}

export default all([takeLatest(ErrorTypes.DISPLAY_ERROR, showError)]);
