import styled, { css } from 'styled-components';
import { Divider as CustomDivider } from 'antd';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => css`
    padding: ${theme.spacings.xxsmall} 0;
  `}

  &:hover {
    cursor: pointer;
  }
`;

export const Divider = styled(CustomDivider)`
  margin: 0;
`;

export const Informations = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 60%;
`;

export const About = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    line-height: 120%;
    text-align: left;
    color: ${theme.colors.darkGray};
  `}
`;

export const Price = styled.small``;
