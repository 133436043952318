import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10rem 0;

  ${({ theme }) => css`
    h5 {
      padding: 0 ${theme.spacings.xsmall};
    }
  `}
`;

export const GoBackWrapper = styled.div`
  width: fit-content;
  cursor: pointer;
`;

export const BillingDetails = styled.div`
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    padding: ${theme.spacings.xsmall};

    tbody {
      margin-top: 2rem;
    }

    th {
      font-weight: normal;
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.darkGray};
    }

    td {
      font-weight: normal;
      font-size: ${theme.font.sizes.xsmall};
    }
  `}
`;

export const BillingItem = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ theme }) => css`
    h6 {
      font-size: ${theme.font.sizes.xsmall};
    }

    h5 {
      padding: 0;
    }
  `}
`;
