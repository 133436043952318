import styled, { css } from 'styled-components';

export const QuantityWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.lightGray};

    padding: ${theme.spacings.xxxsmall} 0;
    border-radius: ${theme.border.radius.normal};
  `}

  > svg {
    margin: 0 15px;
    color: ${({ theme }) => theme.colors.primary};
  }
`;
