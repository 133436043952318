import * as Response from 'utils/response';
import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import { Types as BillingTypes, Creators as BillingCreators } from './actions';
import { Creators as ErrorCreators } from 'store/modules/error/actions';
import api from 'services/api';
import { UnityItemProps } from '../establishment/reducer';
import { RootType } from '../rootReducer';
import { AxiosError } from 'axios';

export function* getBilling() {
  try {
    const unity: UnityItemProps = yield select(
      (state: RootType) => state.establishment.unity
    );

    if (unity) {
      const { status, data: response } = yield call(
        api.get,
        `menu/v1/units/${unity.id}/bills`
      );

      if (status === Response.HTTP_OK) {
        yield put(BillingCreators.getBillingSuccess(response.data));
      }
    }
  } catch (err) {
    const error = err as AxiosError;
    yield all([
      put(ErrorCreators.displayError(error)),
      put(BillingCreators.getBillingFailed()),
    ]);
  }
}

export default all([takeLatest(BillingTypes.GET_BILLING_REQUEST, getBilling)]);
