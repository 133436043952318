import { ActionCreators, createActions } from 'reduxsauce';
import { CategoryItemProps } from './reducer';

export enum Types {
  GET_CATEGORIES_REQUEST = '@category/GET_CATEGORIES_REQUEST',
  GET_CATEGORIES_SUCCESS = '@category/GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_FAILED = '@category/GET_CATEGORIES_FAILED',
  GET_CATEGORIES_INVIEW_MENU = '@category/GET_CATEGORIES_INVIEW_MENU',
}

interface Actions extends ActionCreators {
  getCategoriesRequest: (menu: string) => {
    type: Types.GET_CATEGORIES_REQUEST;
    payload: string;
  };
  getCategoriesSuccess: (data: Array<CategoryItemProps>) => {
    type: Types.GET_CATEGORIES_SUCCESS;
    payload: Array<CategoryItemProps>;
  };
  getCategoriesFailed: () => {
    type: Types.GET_CATEGORIES_FAILED;
  };
  getCategoriesInviewMenu: (category: string) => {
    type: Types.GET_CATEGORIES_INVIEW_MENU;
    payload: string;
  };
}

const CreatedActions = createActions(
  {
    getCategoriesRequest: ['payload'],
    getCategoriesSuccess: ['payload'],
    getCategoriesFailed: [],
    getCategoriesInviewMenu: ['payload'],
  },
  {
    prefix: '@category/',
  }
);

export const Creators = CreatedActions.Creators as Actions;
