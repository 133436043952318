import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootType } from 'store/modules/rootReducer';
import OrderEnabled from 'components/OrderEnabled';
import * as S from './styles';

const FinishOrder = () => {
  const history = useHistory();
  const {
    cart: { quantity },
  } = useSelector((state: RootType) => state);

  return quantity > 0 ? (
    <OrderEnabled>
      <S.Wrapper>
        <S.Order onClick={() => history.push('/cart')}>
          <b>Fazer Pedido</b>
          <span>{quantity}</span>
        </S.Order>
      </S.Wrapper>
    </OrderEnabled>
  ) : null;
};

export default FinishOrder;
