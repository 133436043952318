import { AxiosError, AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from 'services/api';
import { Creators as ErrorCreators } from 'store/modules/error/actions';
import { Creators as MenuCreators } from 'store/modules/menu/actions';
import * as Response from 'utils/response';
import { Creators as UnityCreators, Types as UnityTypes } from './actions';
import { UnitySettingsResponse } from './types';

export function* getUnity(
  action: ReturnType<typeof UnityCreators.getUnityRequest>
) {
  try {
    const { status, data: response } = yield call(
      api.get,
      `restaurant/v1/units/${action.payload}`
    );

    const settings: AxiosResponse<UnitySettingsResponse> = yield call(
      api.get,
      `menu/v1/units/${action.payload}/settings?pagination=0`
    );

    const isIntegratedRestaurantResponse = settings.data.data.map(
      (item) => item.order_enabled
    )[0];

    sessionStorage.setItem(
      'isIntegratedRestaurant',
      String(isIntegratedRestaurantResponse)
    );

    if (status === Response.HTTP_OK && settings.status === Response.HTTP_OK) {
      yield all([
        put(
          UnityCreators.getUnitySuccess({
            ...response.data,
            ...settings.data.data[0],
          })
        ),
        put(MenuCreators.getMenusRequest()),
      ]);

      if (settings.data.data[0].order_enabled) {
        yield put(push('/welcome'));
      }
    }
  } catch (err) {
    const error = err as AxiosError;

    yield all([
      put(ErrorCreators.displayError(error)),
      put(UnityCreators.getUnityFailed()),
    ]);
    yield put(UnityCreators.getUnityFailed());
  }
}

export default all([takeLatest(UnityTypes.GET_UNITY_REQUEST, getUnity)]);
