import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  small {
    max-width: 60%;
    text-align: center;
    ${({ theme }) => css`
      color: ${theme.colors.darkGray};
      font-size: ${theme.font.sizes.medium};
      margin: ${theme.spacings.medium} 0;
    `}
  }
`;

export const Finished = styled.h2`
  position: absolute;
  word-wrap: break-word;
  max-width: 35%;
  text-align: center;
  top: 5rem;
  left: 12rem;

  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-weight: ${theme.font.bold};
  `}
`;

export const Cover = styled.div`
  position: relative;
`;
