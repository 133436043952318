import React from 'react';
import { Title, Small } from 'stories/typography';
import Space from 'stories/utils/Space';
import HeaderImage from 'assets/images/header-image-error-401.svg';
import FooterImage from 'assets/images/footer-image-error-401.svg';
import { QrCode2 } from '@styled-icons/material-rounded/QrCode2';

import * as S from './styles';

const Error401 = () => (
  <S.Wrapper>
    <S.Wave />
    <S.HeaderImage src={HeaderImage} />
    <S.Content>
      <Space size={30} direction="vertical">
        <div>
          <Title level={1}>Oops...</Title>
          <Small>Erro 401: seu token expirou</Small>
        </div>
        <div>
          <QrCode2 size={40} />
          <Title level={6}>
            Leia novamente o QR Code na mesa para carregar o cardápio.
          </Title>
        </div>
      </Space>
    </S.Content>
    <S.FooterImage src={FooterImage} />
  </S.Wrapper>
);

export default Error401;
