import styled from 'styled-components';

export const Wrapper = styled.section`
  background-color: ${({ theme }) => theme.colors.white};
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin: 0;
  padding: 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;

  text-align: center;
  height: 100%;
  margin-bottom: 10rem;
`;

export const Informations = styled.div`
  margin-top: 5rem;

  button {
    background-color: #dd4f50;
    border-radius: 8px;
  }
`;
