import React from 'react';
import { Title } from 'stories/typography';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootType } from 'store/modules/rootReducer';
import Product from 'components/Product';
import FinishOrder from 'components/FinishOrder';

import * as S from './styles';

const Search = () => {
  const history = useHistory();
  const {
    product: { term, searcheds, loading },
    establishment: { origin, unity, table, hide_price_enabled: isHidingPrice },
  } = useSelector((state: RootType) => state);

  const isShowPrice = !isHidingPrice;

  return (
    <>
      <S.Wrapper>
        <div>
          <Title level={6}>Resultados para:</Title>
          <Title>{`"${term}"`}</Title>
        </div>

        <S.ProductList>
          {searcheds.map((product) => (
            <Product
              key={product.id}
              title={product.title}
              description={product.description}
              price={product.price}
              image={product.images[0]?.url || null}
              onClick={() =>
                history.push(
                  `/${origin}/${unity?.id}/${table}/product/${product.id}`
                )
              }
              showPrice={isShowPrice}
            />
          ))}
        </S.ProductList>
        {!loading && searcheds.length === 0 && (
          <S.NoResult>
            <Title level={6}>
              Nenhum resultado encontrado para o termo {`"${term}"`}.
            </Title>
          </S.NoResult>
        )}
      </S.Wrapper>
      <FinishOrder />
    </>
  );
};

export default Search;
