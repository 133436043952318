import { ActionCreators, createActions } from 'reduxsauce';
import { MenuItemProps } from './reducer';

export enum Types {
  GET_MENUS_REQUEST = '@menu/GET_MENUS_REQUEST',
  GET_MENUS_SUCCESS = '@menu/GET_MENUS_SUCCESS',
  GET_MENUS_FAILED = '@menu/GET_MENUS_FAILED',
}

interface Actions extends ActionCreators {
  getMenusRequest: () => {
    type: Types.GET_MENUS_REQUEST;
  };
  getMenusSuccess: (data: Array<MenuItemProps>) => {
    type: Types.GET_MENUS_SUCCESS;
    payload: Array<MenuItemProps>;
  };
  getMenusFailed: () => {
    type: Types.GET_MENUS_FAILED;
  };
}

const CreatedActions = createActions(
  {
    getMenusRequest: ['payload'],
    getMenusSuccess: ['payload'],
    getMenusFailed: [],
  },
  {
    prefix: '@menu/',
  }
);

export const Creators = CreatedActions.Creators as Actions;
