import React, { useEffect } from 'react';
import { Title } from 'stories/typography';
import Space from 'stories/utils/Space';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootType } from 'store/modules/rootReducer';
import { Creators as BillingCreators } from 'store/modules/billing/actions';
import { Divider } from 'antd';

import { getCurrency } from 'utils/helpers';

import * as S from './styles';

const currency = {
  style: 'currency',
  currency: 'BRL',
};

const Bill = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    billing: { data: items, loading, tip, subtotal, total, discount, paid },
    establishment: { unity },
  } = useSelector((state: RootType) => state);

  useEffect(() => {
    if (unity) {
      dispatch(BillingCreators.getBillingRequest());
    }
  }, [dispatch, unity]);

  return (
    <S.Wrapper>
      <Space size={15} direction="vertical">
        <S.GoBackWrapper onClick={() => history.goBack()}>
          <Title level={5}>{'< Voltar'}</Title>
        </S.GoBackWrapper>
        <Title level={5}>Resumo da Conta</Title>
        <S.BillingDetails>
          {items.length > 0 && !loading && (
            <table>
              <thead>
                <tr>
                  <th style={{ width: '50%' }}>Item</th>
                  <th style={{ width: '3%', textAlign: 'right' }}>Qt.</th>
                  <th style={{ width: '22%', textAlign: 'right' }}>Unit.</th>
                  <th style={{ width: '23%', textAlign: 'right' }}>Total</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item, index) => (
                  <tr key={index}>
                    <td style={{ width: '50%' }}>{item.title}</td>
                    <td style={{ width: '3%', textAlign: 'center' }}>
                      {item.quantity}
                    </td>
                    <td style={{ width: '22%', textAlign: 'right' }}>
                      {getCurrency(item.price).toLocaleString(
                        'pt-BR',
                        currency
                      )}
                    </td>
                    <td style={{ width: '23%', textAlign: 'right' }}>
                      {getCurrency(item.total).toLocaleString(
                        'pt-BR',
                        currency
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {loading && <Title level={6}>Carregando informações...</Title>}
          {items.length === 0 && !loading && (
            <Title level={6}>Nenhum item adicionado a sua conta.</Title>
          )}
        </S.BillingDetails>
        <S.BillingDetails>
          <Space size={5} direction="vertical">
            <S.BillingItem>
              <Title level={6}>Subtotal</Title>
              <Title level={6}>
                {getCurrency(subtotal).toLocaleString('pt-BR', currency)}
              </Title>
            </S.BillingItem>
            <S.BillingItem>
              <Title level={6}>Taxa de Serviço</Title>
              <Title level={6}>
                {getCurrency(tip).toLocaleString('pt-BR', currency)}
              </Title>
            </S.BillingItem>
            <S.BillingItem>
              <Title level={6}>Desconto</Title>
              <Title level={6}>
                - {getCurrency(discount).toLocaleString('pt-BR', currency)}
              </Title>
            </S.BillingItem>
            <S.BillingItem>
              <Title level={6}>Já foi pago</Title>
              <Title level={6}>
                - {getCurrency(paid).toLocaleString('pt-BR', currency)}
              </Title>
            </S.BillingItem>
            <Divider />
            <S.BillingItem>
              <Title level={5}>Total</Title>
              <Title level={5}>
                {getCurrency(total).toLocaleString('pt-BR', currency)}
              </Title>
            </S.BillingItem>
          </Space>
        </S.BillingDetails>
      </Space>
    </S.Wrapper>
  );
};

export default Bill;
