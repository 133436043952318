import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import auth, { AuthProps } from './auth/reducer';
import billing, { BillingProps } from './billing/reducer';
import cart, { CartProps } from './cart/reducer';
import category, { CategoryProps } from './category/reducer';
import establishment, { EstablishmentProps } from './establishment/reducer';
import menu, { MenuProps } from './menu/reducer';
import order, { OrderProps } from './order/reducer';
import product, { ProductProps } from './product/reducer';

const reducers = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    establishment,
    menu,
    category,
    product,
    cart,
    order,
    billing,
  });

export type RootType = {
  router: RouterState;
  auth: AuthProps;
  establishment: EstablishmentProps;
  menu: MenuProps;
  category: CategoryProps;
  product: ProductProps;
  cart: CartProps;
  order: OrderProps;
  billing: BillingProps;
};

export default reducers;
