import produce from 'immer';
import { Types as MenuTypes } from './actions';

export type MenuItemProps = {
  id: string;
  title: string;
};

export type MenuProps = {
  loading: boolean;
  data: Array<MenuItemProps>;
};

export const INITIAL_STATE: MenuProps = {
  loading: true,
  data: [],
};

const menu = produce((draft: MenuProps, action) => {
  switch (action.type) {
    case MenuTypes.GET_MENUS_REQUEST:
      draft.loading = true;
      break;
    case MenuTypes.GET_MENUS_SUCCESS:
      draft.loading = false;
      draft.data = action.payload;
      break;
    case MenuTypes.GET_MENUS_FAILED:
      draft.loading = false;
      break;
  }
}, INITIAL_STATE);

export default menu;
