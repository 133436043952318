import { ActionCreators, createActions } from 'reduxsauce';
import { ProductItemProps } from './reducer';

export enum Types {
  GET_PRODUCT_REQUEST = '@product/GET_PRODUCT_REQUEST',
  GET_PRODUCT_SUCCESS = '@product/GET_PRODUCT_SUCCESS',
  GET_PRODUCT_FAILED = '@product/GET_PRODUCT_FAILED',

  SEARCH_PRODUCTS_REQUEST = '@product/SEARCH_PRODUCTS_REQUEST',
  SEARCH_PRODUCTS_SUCCESS = '@product/SEARCH_PRODUCTS_SUCCESS',
  SEARCH_PRODUCTS_FAILED = '@product/SEARCH_PRODUCTS_FAILED',

  OPEN_SEARCH = '@product/OPEN_SEARCH',
  CLOSE_SEARCH = '@product/CLOSE_SEARCH',
}

interface Actions extends ActionCreators {
  getProductRequest: (product: string) => {
    type: Types.GET_PRODUCT_REQUEST;
    payload: string;
  };
  getProductSuccess: (data: ProductItemProps) => {
    type: Types.GET_PRODUCT_SUCCESS;
    payload: Array<ProductItemProps>;
  };
  getProductFailed: () => {
    type: Types.GET_PRODUCT_FAILED;
  };

  searchProductsRequest: (term: string) => {
    type: Types.SEARCH_PRODUCTS_REQUEST;
    payload: string;
  };
  searchProductsSuccess: (data: ProductItemProps) => {
    type: Types.SEARCH_PRODUCTS_SUCCESS;
    payload: Array<ProductItemProps>;
  };
  searchProductsFailed: () => {
    type: Types.SEARCH_PRODUCTS_FAILED;
  };

  openSearch: () => {
    type: Types.OPEN_SEARCH;
  };

  closeSearch: () => {
    type: Types.CLOSE_SEARCH;
  };
}

const CreatedActions = createActions(
  {
    getProductRequest: ['payload'],
    getProductSuccess: ['payload'],
    getProductFailed: [],

    searchProductsRequest: ['payload'],
    searchProductsSuccess: ['payload'],
    searchProductsFailed: [],

    openSearch: [],
    closeSearch: [],
  },
  {
    prefix: '@product/',
  }
);

export const Creators = CreatedActions.Creators as Actions;
