import { ActionCreators, createActions } from 'reduxsauce';
import { AxiosError } from 'axios';

export enum Types {
  DISPLAY_ERROR = '@error/DISPLAY_ERROR',
}

interface Actions extends ActionCreators {
  displayError: (error: AxiosError) => {
    type: Types.DISPLAY_ERROR;
    payload: AxiosError;
  };
}

const CreatedActions = createActions(
  {
    displayError: ['payload'],
  },
  {
    prefix: '@error/',
  }
);

export const Creators = CreatedActions.Creators as Actions;
