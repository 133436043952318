import styled, { css, DefaultTheme } from 'styled-components';
import { BoxProps } from '.';

type WrapperProps = Pick<BoxProps, 'type' | 'padding' | 'status'>;

const wrapperModifiers = {
  default: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.white};
  `,
  success: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.success};
    border: 1px solid ${theme.colors.darkSuccess};
  `,
  warning: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.warning};
    border: 1px solid ${theme.colors.darkWarning};
  `,
  danger: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.danger};
    border: 1px solid ${theme.colors.darkDanger};
  `,
};

const paddingModifiers = {
  small: (theme: DefaultTheme) => css`
    padding: ${theme.spacings.xxxsmall} ${theme.spacings.xsmall};
  `,
  normal: (theme: DefaultTheme) => css`
    padding: ${theme.spacings.xxsmall} ${theme.spacings.xsmall};
  `,
  large: (theme: DefaultTheme) => css`
    padding: ${theme.spacings.xsmall} ${theme.spacings.xsmall};
  `,
};

const statusModifiers = {
  listed: () => css``,
  'not-listed': () => css`
    background-color: #fff9ea !important;
    border: 1px solid #fff9ea;
    opacity: 0.7;
  `,
  suspended: () => css`
    opacity: 0.7;
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, type, padding, status }) => css`
    box-shadow: ${theme.box.shadow};
    border-radius: ${theme.border.radius.large};

    ${!!type && wrapperModifiers[type](theme)}
    ${!!padding && paddingModifiers[padding](theme)}
    ${!!status && statusModifiers[status]}
  `};
`;
