import { Button, Input, Paragraph } from '@getinapp/appetizer';
import Footer from 'components/Footer';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Creators as AuthCreators } from 'store/modules/auth/actions';
import { RootType } from 'store/modules/rootReducer';
import Avatar from 'stories/display/Avatar';
import { Title } from 'stories/typography';
import uuid from 'uniqid';
import * as S from './styles';

const Welcome = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const history = useHistory();
  const {
    establishment: { unity, token },
  } = useSelector((state: RootType) => state);

  const userId = localStorage.getItem('user-id');

  const hasToken = !!token;

  useEffect(() => {
    if (userId == null) {
      localStorage.setItem('user-id', uuid());
    }
  }, [userId]);

  return (
    <S.Wrapper>
      <S.Content>
        <Avatar size={150} alt={unity?.name} src={unity?.profile_image} />
        <S.Informations>
          <Title level={2}>Seja bem vindo ao {unity?.name}</Title>
          <br />
          <Paragraph>
            Adicione seu nome para organizarmos o seu pedido.
          </Paragraph>
          <br />
          <Input
            name="name"
            title="Digite seu nome"
            label="Digite seu nome"
            onChange={(e) => setName(e.target.value)}
          />
          <br />
          <Button
            isFullWidth
            disabled={!name.trim()}
            onClick={() => {
              if (hasToken) {
                dispatch(
                  AuthCreators.setDataRequest({
                    name,
                  })
                );
              }
              history.push('/');
            }}
          >
            Entrar
          </Button>
        </S.Informations>
      </S.Content>

      <Footer />
    </S.Wrapper>
  );
};

export default Welcome;
