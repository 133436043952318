import { ActionCreators, createActions } from 'reduxsauce';
import { UnityItemProps } from './reducer';

export enum Types {
  GET_UNITY_REQUEST = '@establishment/GET_UNITY_REQUEST',
  GET_UNITY_SUCCESS = '@establishment/GET_UNITY_SUCCESS',
  GET_UNITY_FAILED = '@establishment/GET_UNITY_FAILED',

  SET_INFORMATIONS = '@establishment/SET_INFORMATIONS',
}

interface Actions extends ActionCreators {
  getUnityRequest: (unity: string) => {
    type: Types.GET_UNITY_REQUEST;
    payload: string;
  };
  getUnitySuccess: (unity: UnityItemProps) => {
    type: Types.GET_UNITY_SUCCESS;
  };
  getUnityFailed: () => {
    type: Types.GET_UNITY_FAILED;
  };

  setInformations: (informations: {
    token: string;
    origin: string;
    table: number;
  }) => {
    type: Types.SET_INFORMATIONS;
    payload: {
      token: string;
      origin: string;
      table: number;
    };
  };
}

const CreatedActions = createActions(
  {
    getUnityRequest: ['payload'],
    getUnitySuccess: ['payload'],
    getUnityFailed: [],

    setInformations: ['payload'],
  },
  {
    prefix: '@establishment/',
  }
);

export const Creators = CreatedActions.Creators as Actions;
