import { Select as CustomSelect } from 'antd';
import styled from 'styled-components';

export default styled(CustomSelect)`
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: ${({ theme }) => theme.colors.darkSecondary} !important;
  }

  .ant-select:hover .ant-select-selector {
    border-color: ${({ theme }) => theme.colors.darkSecondary} !important;
  }

  .ant-select-open .ant-select-selection {
    box-shadow: none !important;
  }

  &.ant-select-open .ant-select-selection {
    outline: none !important;
    box-shadow: none !important;
  }

  .ant-select-selection__rendered {
    padding: 0px;
    margin: 0px;
    outline: none !important;
    box-shadow: none !important;
  }

  &:hover {
    .ant-select-selector {
      border-color: ${({ theme }) => theme.colors.darkSecondary} !important;
    }
  }

  &.ant-select-selection:active {
    outline: none;
    box-shadow: none;
  }
  &.ant-select-selection:focus {
    outline: none;
    box-shadow: none;
  }
`;
