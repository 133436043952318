import produce from 'immer';
import { Types as CategoryTypes } from './actions';

export type CategoryItemProps = {
  id: string;
  title: string;
  items: Array<{
    id: string;
    title: string;
    description: string;
    price: number;
    images: Array<{ path: string; url: string }>;
  }>;
};

export type CategoryProps = {
  loading: boolean;
  data: Array<CategoryItemProps>;
  categoryInView: string;
};

export const INITIAL_STATE: CategoryProps = {
  loading: true,
  data: [],
  categoryInView: '',
};

const category = produce((draft: CategoryProps, action) => {
  switch (action.type) {
    case CategoryTypes.GET_CATEGORIES_REQUEST:
      draft.loading = true;
      break;
    case CategoryTypes.GET_CATEGORIES_SUCCESS:
      draft.loading = false;
      draft.data = action.payload;
      break;
    case CategoryTypes.GET_CATEGORIES_FAILED:
      draft.loading = false;
      break;
    case CategoryTypes.GET_CATEGORIES_INVIEW_MENU:
      draft.loading = false;
      draft.categoryInView = action.payload;
      break;
  }
}, INITIAL_STATE);

export default category;
