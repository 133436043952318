import { ActionCreators, createActions } from 'reduxsauce';
import { OrdersProps, ProductProps } from './reducer';

export enum Types {
  ADD = '@cart/ADD',
  REMOVE = '@cart/REMOVE',
  EDIT = '@cart/EDIT',
  RESET = '@cart/RESET',

  LOAD_CART_STATUS_REQUEST = '@cart/LOAD_CART_STATUS_REQUEST',
  LOAD_CART_STATUS_SUCCESS = '@cart/LOAD_CART_STATUS_SUCCESS',
  LOAD_CART_STATUS_FAILED = '@cart/LOAD_CART_STATUS_FAILED',

  LOAD_CART_REQUEST = '@cart/LOAD_CART_REQUEST',
  LOAD_CART_SUCCESS = '@cart/LOAD_CART_SUCCESS',
  LOAD_CART_FAILED = '@cart/LOAD_CART_FAILED',

  SEND_CART_REQUEST = '@cart/SEND_CART_REQUEST',
  SEND_CART_SUCCESS = '@cart/SEND_CART_SUCCESS',
  SEND_CART_FAILED = '@cart/SEND_CART_FAILED',
}

interface Actions extends ActionCreators {
  add: (product: ProductProps) => {
    type: Types.ADD;
    payload: ProductProps;
  };
  remove: (product: number) => {
    type: Types.REMOVE;
    payload: number;
  };
  edit: (data: { id: number; quantity: number }) => {
    type: Types.EDIT;
    payload: {
      id: number;
      quantity: number;
    };
  };

  reset: () => {
    type: Types.RESET;
  };

  loadCartStatusRequest: () => {
    type: Types.LOAD_CART_STATUS_REQUEST;
  };

  loadCartStatusSuccess: (payload: string) => {
    type: Types.LOAD_CART_STATUS_SUCCESS;
    payload: string;
  };

  loadCartStatusFailed: () => {
    type: Types.LOAD_CART_STATUS_FAILED;
  };

  loadCartRequest: () => {
    type: Types.LOAD_CART_REQUEST;
  };

  loadCartSuccess: (payload: Array<OrdersProps>) => {
    type: Types.LOAD_CART_SUCCESS;
    payload: Array<OrdersProps>;
  };

  loadCartFailed: () => {
    type: Types.LOAD_CART_FAILED;
  };

  sendCartRequest: (payload: Array<string>) => {
    type: Types.SEND_CART_REQUEST;
    payload: Array<string>;
  };

  sendCartSuccess: () => {
    type: Types.SEND_CART_SUCCESS;
  };

  sendCartFailed: () => {
    type: Types.SEND_CART_FAILED;
  };
}

const CreatedActions = createActions(
  {
    add: ['payload'],
    remove: ['payload'],
    edit: ['payload'],
    reset: [],

    loadCartStatusRequest: [],
    loadCartStatusSuccess: ['payload'],
    loadCartStatusFailed: [],

    loadCartRequest: [],
    loadCartSuccess: ['payload'],
    loadCartFailed: [],

    sendCartRequest: ['payload'],
    sendCartSuccess: [],
    sendCartFailed: [],
  },
  {
    prefix: '@cart/',
  }
);

export const Creators = CreatedActions.Creators as Actions;
