import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  ${({ theme }) => css`
    > div {
      padding: 0 ${theme.spacings.xsmall};
    }
    padding: 10rem 0;
  `}

  p {
    font-size: 17px;
    line-height: 25px;
    text-align: center;
    color: #66707d;
    margin: 30px 0;
  }

  button {
    margin: 30px auto 0 auto;
  }
`;

export const SuccessBox = styled.div`
  float: left;
  position: absolute;
  margin-top: 18%;
  width: 100%;
  text-align: center;
  h2 {
    color: #dd4f50;
  }
`;
