import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { RootType } from 'store/modules/rootReducer';

const OrderEnabled = ({ children }: { children: React.ReactNode }) => {
  const { order_enabled } = useSelector(
    (state: RootType) => state.establishment
  );
  return <Fragment>{order_enabled ? children : null}</Fragment>;
};

export default OrderEnabled;
