import FinishOrder from 'components/FinishOrder';
import OrderEnabled from 'components/OrderEnabled';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootType } from 'store/modules/rootReducer';
import Avatar from 'stories/display/Avatar';
import { Small, Title } from 'stories/typography';
import Space from 'stories/utils/Space';
import * as S from './styles';

const Home = () => {
  const history = useHistory();
  const {
    establishment: { unity, table, order_enabled },
    menu: { loading, data },
    auth: { name },
  } = useSelector((state: RootType) => state);

  useEffect(() => {
    if (unity) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'menu_qr_code',
        unit_id: unity.id,
        restaurant_table: table,
      });
    }
  }, [unity, table]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const { pathname } = window.location;
      const lastPathCharacter = pathname.slice(-1);

      if (pathname.includes('/store') && lastPathCharacter === '/') {
        window.location.pathname = pathname.substring(
          0,
          pathname.lastIndexOf('/')
        );
      }
    }
  }, []);

  return (
    <>
      <S.Wrapper>
        <Space size={40} direction="vertical">
          <S.UnityInformations>
            <S.About>
              <Avatar size={70} alt={unity?.name} src={unity?.profile_image} />
              <div>
                <Small>Seja bem-vindo!</Small>
                <Title level={2}>{order_enabled ? name : unity?.name}</Title>
              </div>
            </S.About>
            <OrderEnabled>
              <S.Table>
                <small>MESA</small>
                <Title level={2}>{table}</Title>
              </S.Table>
            </OrderEnabled>
          </S.UnityInformations>

          <div>
            <Space size={15} direction="vertical">
              {loading
                ? new Array(30)
                    .fill(undefined)
                    .map((_, index) => (
                      <S.Loading key={index} active size="large" />
                    ))
                : data.map((menu) => (
                    <S.MenuWrapper
                      key={menu.id}
                      padding="large"
                      onClick={() =>
                        history.push(`/store/${unity?.id}/${table}/${menu.id}`)
                      }
                    >
                      <Title level={5}>{menu.title}</Title>
                    </S.MenuWrapper>
                  ))}
            </Space>
          </div>
        </Space>
      </S.Wrapper>
      <FinishOrder />
    </>
  );
};

export default Home;
