import { AxiosError } from 'axios';
import { push } from 'connected-react-router';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import api from 'services/api';
import { Creators as ErrorCreators } from 'store/modules/error/actions';
import * as Response from 'utils/response';
import { UnityItemProps } from '../establishment/reducer';
import { RootType } from '../rootReducer';
import { Creators as CartCreators, Types as CartTypes } from './actions';

export function* loadCartStatus() {
  try {
    const unity: UnityItemProps = yield select(
      (state: RootType) => state.establishment.unity
    );

    const table: number = yield select(
      (state: RootType) => state.establishment.table
    );

    if (unity && table) {
      const { status, data } = yield call(
        api.get,
        `menu/v2/units/${unity.id}/orders/review/status`
      );
      if (status === Response.HTTP_OK) {
        yield put(CartCreators.loadCartStatusSuccess(data.data.status));
      }
    }
  } catch (err) {
    const error = err as AxiosError;
    yield all([
      put(ErrorCreators.displayError(error)),
      put(CartCreators.loadCartStatusFailed()),
    ]);
  }
}

export function* loadCart() {
  try {
    const unity: UnityItemProps = yield select(
      (state: RootType) => state.establishment.unity
    );

    const table: number = yield select(
      (state: RootType) => state.establishment.table
    );

    if (unity && table) {
      const { status, data } = yield call(
        api.get,
        `menu/v2/units/${unity.id}/orders/review`
      );
      if (status === Response.HTTP_OK) {
        yield put(CartCreators.loadCartSuccess(data.data.orders));
      }
    }
  } catch (err) {
    const error = err as AxiosError;

    if (error.request.status == '400') {
      return;
    }

    yield all([
      put(ErrorCreators.displayError(error)),
      put(CartCreators.loadCartFailed()),
    ]);
  }
}

export function* sendCart(
  action: ReturnType<typeof CartCreators.sendCartRequest>
) {
  try {
    const unity: UnityItemProps = yield select(
      (state: RootType) => state.establishment.unity
    );

    const table: number = yield select(
      (state: RootType) => state.establishment.table
    );

    if (unity && table) {
      const { status } = yield call(
        api.post,
        `menu/v2/units/${unity.id}/orders`,
        {
          orders: action.payload,
        }
      );
      if (status === Response.NO_CONTENT) {
        yield put(CartCreators.sendCartSuccess());
        yield put(push('/success'));
      }
    }
  } catch (err) {
    const error = err as AxiosError;
    yield all([
      put(ErrorCreators.displayError(error)),
      put(CartCreators.sendCartFailed()),
    ]);
  }
}

export default all([
  takeLatest(CartTypes.LOAD_CART_STATUS_REQUEST, loadCartStatus),
  takeLatest(CartTypes.LOAD_CART_REQUEST, loadCart),
  takeLatest(CartTypes.SEND_CART_REQUEST, sendCart),
]);
