import React from 'react';

import Space from 'stories/utils/Space';
import { Title } from 'stories/typography';
import { getCurrency } from 'utils/helpers';
import * as S from './styles';

type ProductProps = {
  title: string;
  description: string;
  price: number;
  image: string | null;
  onClick?: () => void;
  showPrice: boolean;
};

const styles = {
  cover: { backgroundColor: '#cccccc', borderRadius: 8 },
};

const Product = ({
  title,
  description,
  price,
  image,
  onClick,
  showPrice,
}: ProductProps) => (
  <div onClick={onClick}>
    <S.Wrapper>
      <S.Informations>
        <Space direction="vertical" size={5}>
          <Title level={4}>{title}</Title>
          <S.About>{description}</S.About>
        </Space>
        {showPrice && (
          <S.Price>
            {getCurrency(price).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </S.Price>
        )}
      </S.Informations>
      <div>
        {image && (
          <img
            height="100"
            src={image}
            width="100"
            style={styles.cover}
            alt="Imagem do produto"
          />
        )}
      </div>
    </S.Wrapper>
    {showPrice && <S.Divider />}
  </div>
);

export default Product;
