import React, { useState } from 'react';
import { Plus } from '@styled-icons/boxicons-regular/Plus';
import { Minus } from '@styled-icons/boxicons-regular/Minus';
import { Title } from 'stories/typography';

import * as S from './styles';

type QuantityProps = {
  value?: number;
  min?: number;
  onChange?: (value: number) => void;
};

const Quantity = ({ value = 0, onChange, min = 0 }: QuantityProps) => {
  const [quantity, setQuantity] = useState(value);

  React.useEffect(() => {
    setQuantity(value);
  }, [value]);

  const handleQuantity = (value: number) => {
    if (!(quantity + value < min)) {
      if (onChange) onChange(quantity + value);
      setQuantity(quantity + value);
    }
  };

  return (
    <S.QuantityWrapper>
      <Minus size={25} onClick={() => handleQuantity(-1)} />
      <Title level={2}>{quantity}</Title>
      <Plus size={25} onClick={() => handleQuantity(1)} />
    </S.QuantityWrapper>
  );
};

export default Quantity;
