import { Button } from '@getinapp/appetizer';
import styled, { css } from 'styled-components';

export const Footer = styled.footer`
  min-height: 5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => css`
    padding: ${theme.spacings.xsmall};
  `}
`;

export const LogoWrapper = styled.div`
  ${({ theme }) => css`
    font-style: normal;
    font-weight: ${theme.font.normal}
    font-size: ${theme.font.sizes.xsmall};
    line-height: ${theme.font.sizes.large};

    color: ${theme.colors.darkDown};
  `}
`;

export const DownloadButton = styled(Button)`
  min-width: 9rem;
  min-height: 2.8rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
